import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useState } from "react";
import { Typography, Stack, IconButton, Collapse, Box } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import ItemsCarousel from 'react-items-carousel';
import { Bracketscurly2 } from "../assets/icons/Bracketscurly2";



const TestimonialItem = (props: { name: string, content: string, icon: any, reference : any }) => {
  return (
    <>


<div className="slider center py-5 testimonial-height">
                <div className="back_border p-3 rounded-1 mx-2 testimonial-height">
                    <div className="d-flex text-white box_bottm_border">
                        <p>{props.content}</p>
                        <div><i className="bi bi-twitter twitter-padding "></i></div>
                        
                    </div>
                    <div className="d-flex gap-3">
                        <div>
                        {props.icon} 
                        </div>
                        <div className="text-white fs-14 my-auto">
                            <p className="mb-0"> {props.name}</p>
                            <p className="mb-0"> {props.reference}</p>
                        </div>
                    </div>
                </div>
                </div>


    </>
  );
};

export default TestimonialItem;