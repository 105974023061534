import { Box, Typography, Container, Divider, SvgIcon, Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowRight } from "@mui/icons-material";
import blogs_1 from "../assets/img/blogs1.png";
import { IGetBlogsDet, IGetBlogId } from "../redux/api/types";
import { useGetBlogDetQuery, useGetBlogidQuery } from "../redux/api/promptApi";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/store";
import BlogIndexPage from "../pages/BlogIndexPage";
import { setModule } from "../redux/features/genieSlice";
import img54 from "../assets/newimg/logo.png";

import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";




const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>

const ADiveintoBlogs = () => {

  const params = useParams();

  const [blogDetails, setBlogDetails] = useState<IGetBlogId[]>([]);

  const [blog_Id, setBlogId] = useState<string | null | undefined>(params.blog_id);
  const getblogdetails = useGetBlogidQuery({ blog_id: blog_Id || '' });
  console.log(getblogdetails);
  

 useEffect(() => {
    if (getblogdetails && getblogdetails.data) {

      setBlogDetails([...getblogdetails.data]);
    }
  }, [getblogdetails])


  const handleGetBlogId = () => {
    if (getblogdetails.data && getblogdetails.data.length > 0) {
      const blogIdFromGetBlogDetails = getblogdetails.data[0]._id;
      setBlogId(blogIdFromGetBlogDetails);
    }
  };

  useEffect(() => {
    handleGetBlogId();
  }, [getblogdetails]);

  const navigation = blogDetails.map((blog, index) => {
  })

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const handleLearnMore = () => {
    const navigation = blogDetails.map((blog, index) => {
      if (blog.cat == 'cat1' || blog.cat == 'SMART CONTRACTS') {
        debugger
        dispatch(setModule("SMART CONTRACTS"));
        navigate('/');
      }
      else {
        dispatch(setModule("Any Code"));
        navigate('/Anycode_landing');
      }
    })
  }
const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .then((response) => {
        if ('data' in response) {
          const status = response.data.status;
          console.log(status);
          if (status === 'success') {
            toast.success(response.data.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
        } else {
          // Error case
          console.error('Error sending query:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error sending query:', error);

      });
  }
  return (
    <>

      <Container maxWidth="lg" style={{ backgroundColor: 'white' }}>
        <Box py={5}>


          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', maxHeight: '485px', overflow: 'hidden'  }}>
            {blogDetails.map((blog, index) => (
              <>
                <img className="ellipse-18" alt={"Code Genie:" + (blog.blog_title_draft)} style={{ width: '1152px', height: 'auto' }} src={(blog.blog_image_path ? blog.blog_image_path : blog.blog_image_path_draft)} title={"Code Genie:" + (blog.blog_title_draft)} />

              </>
            ))}

          </div>

          <br></br>  <br></br>


          <Typography
            variant="h3"
            color="black"
            fontWeight={600}
            textAlign="center"
            mb={10}


          >
            {blogDetails.map((blog, index) => (
              <>
                {blog.blog_title_draft}
              </>
            ))}
          </Typography>



          <Typography
            variant="h6"
            color="black"
            fontWeight={300}
            textAlign="justify"
            mb={10}

          >

            {blogDetails.map((blog, index) => (
              <div key={index}>
                {(blog.blog_det_draft)?.split('\n\n').map((paragraph, idx) => {
                  if (paragraph.includes('://')) {
                    return (
                      <div key={idx}>
                        <div dangerouslySetInnerHTML={{ __html: paragraph.replace("<h3>","</br><h3>") }} />   
                        <br />
                      </div>
                    );
                  } else if (!paragraph.match(/<h3>/) && (paragraph.match(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/))) {
                    return (
                      <div key={idx}>
                        <br />
                        <span>{paragraph.replace(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/, '')}</span>
                        <br />
                      </div>
                    );
                  } else if (paragraph.includes(':')) {
                    let parts = paragraph.split(':');
                    let label = parts[0];
                    let content = parts.slice(1).join(':');
                    return (
                      <div key={idx}>
                        <br />
                        <h3>{label}: {content}</h3>
                        <br />
                      </div>
                    );
                  }
                  return (
                    <div key={idx}>
                      <div dangerouslySetInnerHTML={{ __html: paragraph.replace("<h3>","</br><h3>") }} />    
                      <br />
                    </div>
                  );
                })}
              </div>
            ))}





        <Button variant="contained" 

        sx={{ background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)", color: "white", float: 'left' , margin: '8px', padding: '10px 30px' }}
            onClick={handleLearnMore}
          >
            Learn More<KeyboardArrowRight />
          </Button>

          </Typography>

         
        </Box>


      </Container>

<div className="py-5 mx-sm-0 mx-2 backgroundbg"
    // className="footer py-sm-5 py-3"
    // style={{
    //   backgroundImage: "url(./img/icon-bg.png)",
    //   backgroundRepeat: "no-repeat"
    // }}
  >
    <div className="container">
      <div className="row px-sm-0 px-2">
        <div className="col-lg-3 text-white text-lg-start text-center">
          <div>
            <div>
              {/* <img src="./img/logo.png" className="img-fluid" alt="" /> */}
              <img src={img54} alt="" />
            </div>
            <p className="fw_samibold">
              OceSha/Code Genie Los Angeles, Ca 310-748-8901
            </p>
          </div>
        </div>
        <div className="col-lg-3 text-white d-lg-none d-block  text-lg-start text-center">
          <div>
            <h6 className="f_heading mb-4">
              Get news in your mailbox right away!
            </h6>
            <p className="fw_samibold">

              In addition, stay in loop for our exclusive launches and limited
              time deals.
            </p>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Search" />
            <button className="btn btn-primary" type="submit">
              Subscribe
            </button>
          </div>
        </div>
        
        <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
          <div>
          <div className="text-white">
                <h6 className="fw_samibold">Products</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a href="https://ai-course.code-genie.ai/courses/ArtificialIntelligence">AI Course</a>
                  </li>
                  <li>
                    <a href="https://consulting.code-genie.ai/">AI Consulting</a>
                  </li>
                  <li>
                    <a href="https://www.amazon.com/TECH-WARZ-Rise-Artificial-Intelligence-ebook/dp/B0C7BPRX9J/ref=sr_1_1?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-1">AI Book</a>
                  </li>
                  <li>
                    <a href="https://www.amazon.com/TECH-WARZ-BLOCKCHAIN-CRYPTOCURRENCY-Money-ebook/dp/B0C83TGQSY/ref=sr_1_2?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-2">Blockchain Book</a>
                  </li>
                </ul>
              </div>
          </div>
        </div> 
        <div className="col-lg-2 col-sm-4  text-lg-start text-sm-end">
          <div>
            <div className="text-white">

              <h6 className="fw_samibold">Support</h6>
              <ul
                className="ps-0 mt-3 footer_dec"
                style={{ listStyle: "none" }}
              > 
                <li>
                  <a href="/terms">Terms and Conditions</a>
                </li>
                <li>
                  <a href="/privacy">Privacy &amp; Cookie policy</a>
                </li>
              </ul>

            </div>
          </div>
        </div>
        <div className="col-lg-3 text-white d-lg-block d-none">

          <div>
            <h5 className="mb-4 f_heading">
              Get news in your mailbox right away!
            </h5>
            <p className="fw_samibold">

              In addition, stay in loop for our exclusive launches and limited
              time deals.
            </p>
          </div>
             {/* News Subscribe */}
             <div className="input-group mb-3" onSubmit={handleSubmit(onSubmitHandler)}>
                    <input className={`form-control ${errors["email"] ? "error" : ""}`}
                      {...register("email")}
                      type="email"
                      placeholder="Enter your mail"
                      // error={!!errors["email"]}
                      // helperText={errors["email"]?.message}
                      onChange={(e) => setSubscribeVal({ ...subscribeVal, email: e.target.value })} />
                    <button className="btn btn-primary" type="submit" onClick={handleSubscribeNews}>
                      Subscribe
                    </button>
                    {errors["email"] && (
                      <span className="error-message">{errors["email"]?.message}</span>
                    )}
                  </div>
                  {/*  */}
        </div>
      </div>
    </div>
  </div>
    </>
  );
};

export default ADiveintoBlogs;