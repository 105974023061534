import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useState } from "react";
import { Typography, Stack, IconButton, Collapse, Box } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import ItemsCarousel from 'react-items-carousel';
import { Bracketscurly2 } from "../assets/icons/Bracketscurly2";
import { Target } from "../assets/icons/Target";



const AuditItem = (props: { title: string; content: string, icon: any }) => {
  return (
    <>
      {/* <div className="frame-33">
        <div className="bracketscurly-1-wrapper">
          {props.icon}
        </div>
        <div className="frame-34">
          <p className="text-wrapper-29">{props.title}</p>
          <p className="text-wrapper-30">
          {props.content}
          </p>
        </div>
      </div> */}




      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height">
          <div className="bracketscurly-1-wrapper ">
          {props.icon}
          </div>
          <h5 className="my-3">{props.title}</h5>
          <p className="mb-0 fs-14">
          {props.content}
          </p>
        </div>
      </div>

    </>
  );
};

export default AuditItem;
