/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const Gitpullrequest = ({ color = "white", className }: Props): JSX.Element => {
  return (
    <svg
      className={`gitpullrequest ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.8573 20.25C8.30705 20.25 9.4823 19.0747 9.4823 17.625C9.4823 16.1753 8.30705 15 6.8573 15C5.40755 15 4.2323 16.1753 4.2323 17.625C4.2323 19.0747 5.40755 20.25 6.8573 20.25Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M6.8573 9C8.30705 9 9.4823 7.82475 9.4823 6.375C9.4823 4.92525 8.30705 3.75 6.8573 3.75C5.40755 3.75 4.2323 4.92525 4.2323 6.375C4.2323 7.82475 5.40755 9 6.8573 9Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M6.8573 9V15"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M18.1073 20.25C19.557 20.25 20.7323 19.0747 20.7323 17.625C20.7323 16.1753 19.557 15 18.1073 15C16.6576 15 15.4823 16.1753 15.4823 17.625C15.4823 19.0747 16.6576 20.25 18.1073 20.25Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M18.1073 15V11.2406C18.1057 10.0451 17.6304 8.89893 16.7854 8.05313L13.2323 4.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M13.2323 8.25V4.5H16.9823"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

Gitpullrequest.propTypes = {
  color: PropTypes.string,
};
