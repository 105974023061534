import { useState, useContext, useEffect, FormEvent } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  SvgIcon,
  FormControlLabel,
  Switch,
  Divider,
  Stack,
  Modal,
  Paper,
  TextField
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

import { ReactComponent as Logo } from "../../logo.svg";
import { ReactComponent as CrownIcon } from "../../assets/ico_crown.svg";
import { ReactComponent as ProductIcon } from "../../assets/ico_product.svg";
import { ReactComponent as DownIcon } from "../../assets/ico_downarr.svg";

import { ColorModeContext } from '../../theme';
import { useLazyGetModulesQuery, useLazyGetProductQuery } from "../../redux/api/productApi";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";
import { logout } from "../../redux/features/userSlice";
import { useLazyCreateCheckoutSessionQuery } from "../../redux/api/subscriptionApi";
import PayNowButton from "../../pages/payment/payNowButton";
import "../../pages/custom_style.css";
import { IPlanDetail } from "../../redux/api/types";
import { useLazyRemainingWishCountQuery, useRemainingWishCountQuery } from "../../redux/api/genieApi";
import logo from "../../assets/newimg/logo.png";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  marginLeft: 10,
  border: "1px solid #1D252D",
  borderRadius: 8,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
    background: theme.palette.mode === "dark" ? "white" : "#1D252D",
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "linear-gradient(#0168B5, #4BA5EB)"
        : "rgba(255,255,255,1)",
    boxSizing: "border-box",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette
    // .mode === "dark" ? "#1A2027" : "#fff",
    .mode === "dark" ? (localStorage.getItem('userRole') === 'user' ? "#1A2027" : "#F9FBFC") : (localStorage.getItem('userRole') === 'user' ? "#1A2027" : "#F9FBFC"),

  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
console.log('Theme=>'+Item);


function Header() {

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const colorMode = useContext(ColorModeContext);

  const [modules, setModules] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [openSetting, setOpenSetting] = useState(false);
  const [openModal, setOpenModal] = useState("Password");



  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const genieSelector = useAppSelector((state) => state.genieState);
  const user = useAppSelector((state) => state.userState.user);

  if (user && user.role) {
    const { role } = user;
    localStorage.setItem('userRole', role);
  }

const userRole = localStorage.getItem('userRole');
  
  // Check if the user is logged in
  const isAuthenticated = localStorage.getItem("logged_in");

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [createCheckoutSession, checkoutState] = useLazyCreateCheckoutSessionQuery();
  const [getModules, getState] = useLazyGetModulesQuery();
  const smart_contracte_complexity = localStorage.getItem("complexity");
  const [openPaidModal, setOpenPriceModal] = useState(false);
  const [remainingWishCount, remWishState] = useLazyRemainingWishCountQuery();
  const remainingWishCountQuery = useRemainingWishCountQuery({ "email": user?.email || "" })

  const [period, getPeriodState] = useState("monthly");
  const [getProduct, getProductState] = useLazyGetProductQuery();
  const [plans, setPlans] = useState<IPlanDetail[]>([
    {
      user: 1,
      plan_name: "",
      total_wishes: 0,
      price: "",
      period: "",
      product_id: "",
      price_id: "",
      subscription_level: "",
      feature: [],
      contracts: 0,
      type: "",
      button_text: "",
    },
  ]);
  const [wishRemCount, setWishRemCount] = useState(0);


  useEffect(() => {
    getModules("CodeGenie");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (remainingWishCountQuery.isSuccess)
      setWishRemCount(remainingWishCountQuery.data?.remaining_wish_count);
  }, [remainingWishCountQuery]);

  useEffect(() => {
    if (remWishState.isSuccess) {
      //console.log(remWishState.data?.remaining_wish_count);
      setWishRemCount(remWishState.data?.remaining_wish_count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remWishState]);

  useEffect(() => {
    if (checkoutState.isSuccess) {
      window.location = checkoutState.data?.checkout_url;
      //window.open(checkoutState.data?.checkout_url, '_blank');
    }
  }, [checkoutState, navigate]);

  useEffect(() => {

    const { data } = getProductState;
    if (data?.plan_details) {
      //console.log(data?.plan_details);
      setPlans([...data?.plan_details]);
    }
    else
      setPlans([
        {
          user: 1,
          plan_name: "",
          total_wishes: 0,
          price: "",
          period: "",
          product_id: "",
          price_id: "",
          subscription_level: "",
          feature: [],
          contracts: 0,
          type: "",
          button_text: "",
        },
      ]);
  }, [getProductState]);

  useEffect(() => {
    if (getState.isSuccess)
      setModules(getState.data.filter((item) => item !== ""));
  }, [getState]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    localStorage.setItem("logged_in", "false");
    //console.log("loggedin:" + localStorage.getItem("logged_in"));
    dispatch(logout());
    var module = localStorage.getItem("module");
    console.log("module111...." + module);
    if (module == "Any Code"){
      window.location.href = "https://www.rohanhall.com/code-auditor";
      //navigate("/Anycode_landing");
    }
      
    else
      navigate("/");
    toast.success("Logout success");
    localStorage.removeItem("payment");
    localStorage.removeItem("msg");
    localStorage.removeItem("complexity");
    localStorage.removeItem("module");
    localStorage.removeItem("subscription_level");
    localStorage.removeItem("free_plan");
    localStorage.removeItem("userRole");
    localStorage.removeItem("localStorage_msg");
    localStorage.removeItem("allSet");
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>, subscription_level: string) {
    e.preventDefault();
    //validate before submit for same subscription level

    if (localStorage.getItem("subscription_level") == subscription_level) {
      var cn = window.confirm("You are trying to udgrade the same plan, that you have already registered. Do you want to continue?");
      if (!cn) return false;
    }

    if (subscription_level === "Free") {
      if (user?.email) {
        navigate("/codegenie/smart_contracts");
      } else {
        navigate("/signin");
      }
    } else {
      if (isAuthenticated != "true") {
        navigate('/signin');
      } else {
        createCheckoutSession({ "emailid": user?.email || "", "username": user?.name || "", "subscription_level": subscription_level });
      }

    }
  }


  const handleSetting = () =>{
    navigate('/codegenie/settings');
  }
  const genieScreenwishCount = localStorage.getItem('genieScreenwishCount');
  return (
    <>
      <Container maxWidth="xl">
        <nav className="navbar navbar-expand-md">
          <div className="container-fluid">
            <a className="navbar-brand" href="#"><img src={logo} alt="" /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
              <span className=""><i className="bi bi-list text-white"></i></span>
            </button>
            {isMobile ? 
            
            <div style={{ padding: '1%' }} >
            <ManageAccountsIcon style={{ fontSize: '150%' }} onClick={handleSetting}></ManageAccountsIcon>
          </div>:null}
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                
                
                
               {/*  <li className="mt-1">
                  {(process.env.REACT_APP_SHOW_PRODUCT_MENU == "true") && (
                    <>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        startIcon={
                          <SvgIcon>
                            <ProductIcon />
                          </SvgIcon>
                        }
                        endIcon={
                          <SvgIcon>
                            <DownIcon />
                          </SvgIcon>
                        }
                        sx={{ color: "inherit" }}
                      >
                        Products
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {modules.map((module, index) => (
                          <MenuItem
                            key={`menu_item_${index}`}
                            onClick={() => {
                              handleClose();
                              navigate(
                                `codegenie/${module.toLowerCase().replace(" ", "_")}`
                              );
                              //console.log("module::"+module);
                              localStorage.setItem("module", module);
                              localStorage.removeItem("payment");
                              localStorage.removeItem("msg");
                              localStorage.removeItem("localStorage_msg");
                              localStorage.removeItem("complexity");
                              dispatch(setModule(module));
                            }}
                          >
                            {module}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>)
                  }
                </li> */}
                <li>

                </li>
                <li>
                  {!isMobile ? (
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: { xs: "none", md: "flex" },
                        ml: 12,
                        gap: 6,
                      }}
                    >
<div className="nav-item">
  {/* <a className="nav-link text-white active" aria-current="page" href="/">Home</a> */}
  <li className="nav-item d-flex justify-content-center" style={{ marginLeft: '114%' }}>
    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5">
      <div className="nav-item">
        <a
          className="nav-link nav_simple smart-nav-1"
          style={{ color: "#225CD7", fontWeight: 900, }} /* Adjust the font size as per your preference */
          href="/"
        >
          CODE AUDITOR  
        </a>
      </div>
    </ul>
  </li>
</div>

                      {/*<Box
                    sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
                  >
                    <Button>Invite Team Members</Button>
                      </Box>*/}

                      <Box className="headerBox"
                        sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
                      >
                        {smart_contracte_complexity && `Complexity: ${smart_contracte_complexity}`}
                      </Box>
                      <Box className="headerBox"
                        sx={{ flexGrow: 1, display: "flex", justifyContent: "left" }}
                      >
                        {smart_contracte_complexity === "Low" ? (
                          <label>Price: $99</label>
                        ) : smart_contracte_complexity === "Medium" ? (
                          <label>Price: $399</label>
                        ) : smart_contracte_complexity === "High" ? (
                          <label>Price: $999</label>
                        ) : smart_contracte_complexity === "Very High" ? (
                          <label>Price: $2,999</label>
                        ) : (
                          <p></p>
                        )}


                      </Box>
                    </Box>
                  ) : null}
                </li>
                {/*<li className="nav-item">
                <a className="nav-link disabled text-white">Pricing</a>
                  </li>*/}
              </ul>
              <Box sx={{ flexGrow: 0, display: { xs: "block", md: "block" } }}>
                {/* <FormControlLabel
              value="start"
              control={<AntSwitch onChange={colorMode.toggleColorMode} />}
              label="Darkmode"
              labelPlacement="start"
            /> */}
           

                <Box sx={{ display: "inline-block", paddingRight: 5, paddingX: 2, paddingY: 1 }}>
                  <PayNowButton></PayNowButton>
                </Box>



                <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 0,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button> 

              </Box>




            </div>

            <div className={isMobile ? 'mt-2' : ''}>
              {
                
                //((localStorage.getItem("module") != "SMART CONTRACT") && userSelector.user?.email) && (
                (genieScreenwishCount !== null && localStorage.getItem("module") != "SMART CONTRACTS" && (wishRemCount <= 0 || localStorage.getItem("free_plan") !== "1" ||localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")) && (
                  <Button
                    //startIcon={
                    //  <SvgIcon>
                    //    <CrownIcon />
                    //  </SvgIcon>
                    //}
                    sx={{
                      background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                      marginLeft: isMobile ? 0 : 5,
                      paddingLeft: 5,
                      color: "white",
                      paddingX: 2,
                      paddingY: 1,
                    }}
                    onClick={() => {
                      const value = {
                        _id: "",
                        product_name: "CodeGenie",
                        product_module: genieSelector.module ? genieSelector.module : ""
                      };
                      getProduct(value);
                      // setOpenPriceModal(true)
                      navigate('/pricing');
                    }}
                  >
                    {(localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3") ? "Upgrade Now" : "Pay Now"}
                  </Button>
                )
              }
              
            </div>
            {!isMobile ? 
            <div style={{ padding: '1%', cursor: 'pointer' }}>
              <ManageAccountsIcon style={{ fontSize: '150%' }} onClick={handleSetting}></ManageAccountsIcon>
            </div>:null
            }
          </div>
        </nav>


      </Container>
      <Divider />

      {isMobile ? (
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "flex" },
            ml: 2,
            gap: 2,
          }}
        >

          {/*<Box
        sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
      >
        <Button>Invite Team Members</Button>
          </Box>*/}

          <Box className="headerBox"
            sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
          >
            {smart_contracte_complexity && `Complexity: ${smart_contracte_complexity}`}
          </Box>
          <Box className="headerBox"
            sx={{ flexGrow: 1, display: "flex", justifyContent: "left" }}
          >
            {smart_contracte_complexity === "Low" ? (
              <label>Price: $99</label>
            ) : smart_contracte_complexity === "Medium" ? (
              <label>Price: $399</label>
            ) : smart_contracte_complexity === "Heigh" ? (
              <label>Price: $999</label>
            ) : smart_contracte_complexity === "Very Heigh" ? (
              <label>Price: $2,999</label>
            ) : (
              <p></p>
            )}


          </Box>
        </Box>
      ) : null}

      <Modal
        open={openPaidModal}
        onClose={() => setOpenPriceModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "98%" : 600,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            borderRadius: "5px",
            // boxShadow: '1px 1px 1px 1px'

          }}
        >
          <h4 id="child-modal-description" style={{ padding: '1%' }}>Pricing Plans</h4>
          <p>Get 2 months free with yearly plan</p>
          <Stack flexDirection="column" sx={{ width: '100%', borderTop: '1px solid' }}>
            <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <Box component="div" textAlign="center" sx={{ p: 2 }}>

                <Button
                  size="large"
                  //variant={period==="monthly"?"contained":"text"}
                  sx={{
                    background: `${period === "monthly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                    color: `${period === "monthly" ? "white" : "inherit"}`,
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    getPeriodState("monthly");
                  }}
                >
                  Monthly billing
                </Button>
                <Button
                  size="large"
                  //variant={period==="yearly"?"contained":"text"}
                  sx={{
                    background: `${period === "yearly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                    color: `${period === "yearly" ? "white" : "inherit"}`,
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    getPeriodState("yearly")
                  }}

                >
                  Yearly billing
                </Button>
              </Box>
              <Stack flexDirection="row" spacing={2} >
                {plans.filter((item) => item.period === period).map((plan, index) => (
                  <>
                    <Item style={{/* margin:"5px" */margin: "1%", width: "100%", padding: "1%" }} key={`plan_item_${index}`}>
                      <Typography sx={{ textAlign: 'center' }}>
                        {plan.total_wishes} {index == 0 ? 'Bug Fixes' : 'Audit Code'}
                      </Typography>
                      <Typography sx={{ textAlign: 'center' }}>
                        ${plan.price}
                      </Typography>

                      <form method="POST" onSubmit={(event) => handleSubmit(event, plan.subscription_level)} style={{ margin: '10%' }}>
                        <Button
                          className="button"
                          size="large"
                          fullWidth
                          type="submit"

                          sx={{
                            background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                            color: "white",
                            paddingX: 2,
                            paddingY: 1,
                          }}
                        >
                          {plan.button_text}
                        </Button>
                      </form>
                    </Item>
                  </>
                ))}
              </Stack>
            </Box>
            <Box component="div" textAlign="center" sx={{ p: 2 }}>
              <Button
                onClick={() => {
                  setOpenPriceModal(false);
                }}
                variant="contained"
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>


      {/* User Setting Popup */}
      <Modal
        open={openSetting}
        onClose={() => setOpenSetting(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "98%" : 600,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            borderRadius: "5px",
            // boxShadow: '1px 1px 1px 1px'

          }}
        >
          <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
            <h5 id="child-modal-description" style={{ padding: '1%' }}>Settings</h5>
            <Box component="div" textAlign="center" sx={{ padding: '2% 0%' }}>

              <Button
                size="large"
                //variant={period==="monthly"?"contained":"text"}
                sx={{
                  background: `${openModal === "Password" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                  color: `${openModal === "Password" ? "white" : "inherit"}`,
                  paddingX: 2,
                  paddingY: 1,
                }}
                onClick={() => {
                  setOpenModal("Password");
                }}
              >
                Password
              </Button>
              <Button
                size="large"
                //variant={period==="yearly"?"contained":"text"}
                sx={{
                  background: `${openModal === "Products Purchased" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                  color: `${openModal === "Products Purchased" ? "white" : "inherit"}`,
                  paddingX: 2,
                  paddingY: 1,
                }}
                onClick={() => {
                  setOpenModal("Products Purchased")
                }}

              >
                Products Purchased
              </Button>
              <Button
                size="large"
                //variant={period==="yearly"?"contained":"text"}
                sx={{
                  background: `${openModal === "Subscription" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                  color: `${openModal === "Subscription" ? "white" : "inherit"}`,
                  paddingX: 2,
                  paddingY: 1,
                }}
                onClick={() => {
                  setOpenModal("Subscription")
                }}

              >
                Subscription
              </Button>
            </Box>
          </div>
          <Stack flexDirection="column" sx={{ width: '100%', borderTop: '1px solid' }}>
            <Box sx={{ display: "inline-block", margin: "0 10px" }}>

              <Stack flexDirection="row" spacing={2} >
                {openModal == 'Password' ?
                  <div style={{display: 'grid', padding: '1%', width: '100%'}}>
                    <div style={{padding: '1%'}}>
                    <Typography sx={{ textAlign: 'Left', fontWeight: 'bold' }}>
                      UserName
                    </Typography>
                    <TextField placeholder="username" style={{width: '100%'}}/>
                    </div>
                    <div style={{padding: '1%'}}>
                    <Typography sx={{ textAlign: 'Left', fontWeight: 'bold' }}>
                      Password
                    </Typography>
                    <TextField placeholder="Enter current password" style={{width: '100%'}}/>
                    </div>
                    <div style={{padding: '1%'}}>
                    <Typography sx={{ textAlign: 'Left', fontWeight: 'bold' }}>
                      New Password
                    </Typography>
                    <TextField placeholder="Enter new password" style={{width: '100%'}}/>
                    </div>
                  </div>
                  : null}
              </Stack>
            </Box>
            <Box component="div" textAlign="center" sx={{ p: 2 }}>
            <Button
               /*  onClick={() => {
                  setOpenSetting(false);
                }} */
                variant="contained"
                sx={{ mr: 2 }}
              >
                Submit
              </Button>
              <Button
                onClick={() => {
                  setOpenSetting(false);
                }}
                variant="contained"
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
      {/*  */}
    </>
  );
}
export default Header;
