import { useState, useEffect } from "react";
import {
    Container,
    Box,
    Typography,
    Divider,
    Autocomplete,
    TextField,
    SvgIcon,
    Modal,
    Stack,
    Grid,
    fabClasses,
    Button,
    InputLabel,
    FormControlLabel,
    Switch,
    MenuItem,
    Backdrop
} from "@mui/material";
import { Link } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowLeft, KeyboardArrowRight, SettingsPowerTwoTone } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IDeleteBlog, IGetBlogsDet } from "../../redux/api/types";
import { useGetBlogDetailsAdminQuery, useBlogDetailsUpdateMutation, useBlogDetailsDraftMutation, useBlogDetailsPublishMutation, useBlogsCountQuery, useGenerateBlogsMutation, useDeleteBlogMutation } from "../../redux/api/promptApi";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as EditIcon } from '../../assets/ico_edit.svg';
import { redirect, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LinkIcon from '@mui/icons-material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import { object, string, TypeOf, number } from "zod";
import { toast } from "react-toastify";
import { ReactComponent as AddIcon } from '../../assets/ico_plus_white.svg';
import { ReactComponent as WishIcon } from "../../assets/ico_wish.svg";
import { ReactComponent as EyeIcon } from "../../assets/ico_eye.svg";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MagicWand from "../../components/MagicWand";

import { updateNamedExports } from "typescript";




interface IGetBlogsAdmin {
    blog_title: string,
    blog_image_string: string,
    blog_summary: string,
    blog_image_path: string,
    cat: string,
    blog_det: string,
    url: string,
    _id: string,
    limitval: number,
    skipval: number,
    query: string,
    serialNumber: number,
    blog_image_path_small: string,
    image_original_id: string,
    image_updated: string,
    mode: string,
    blog_det_draft: string,
    blog_image_path_draft: string,
    blog_summary_draft: string,
    blog_title_draft: string,
    url_draft: string,
    seo_score: number,
    total_views: number,
    rattings: number,
    publish_date: string
}

interface IGenerateContent {
    cat: string,
    title: string,
    prompt: string,
    /*     blog_title_draft: string,
        blog_image_string: string,
        blog_summary_draft: string,
        blog_image_path_draft: string,
        blog_det_draft: string,
        url_draft: string,
        image_update: string,
        mode: string, */
}

interface BlogPreviewResponseStruct {
    data: {
        msg: BlogPreviewResponseStructMsg
    };
    status: string;
}

interface BlogPreviewResponseStructMsg {
    blog_det_draft: string;
    blog_image_path_draft: string;
    blog_image_string: string;
    blog_summary_draft: string;
    blog_title_draft: string;
    cat: string;
    image_updated: string;
    mode: string;
    url_draft: string;
    blogid: string;
};


const generateContent = object({
    cat: string(),
    title: string(),
    prompt: string(),
})

const blogUpdate = object({
    id: string(),
    blog_title: string(),
    blog_image: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string(),
    publish_date: string()
})

const blogDraft = object({
    id: string(),
    blog_title: string(),
    blog_image: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string()
})

const blogPublish = object({
    id: string(),
    blog_title: string(),
    blog_image: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string()
})

export type BlogUpdate = TypeOf<typeof blogUpdate>
export type BlogDraft = TypeOf<typeof blogDraft>
export type BlogPublish = TypeOf<typeof blogPublish>

export type GenerateCont = TypeOf<typeof generateContent>




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: '#1377C3', 
        color: 'lightblack',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'white', // Set background color for odd rows to white
        color: 'black', // Set text color for odd rows to black
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#F2F6FA', // Set background color for even rows to gray
        color: 'darkslategray',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const customInputStyle = {
    color: 'Black',
    border: '1px solid #CBCBCB',
};


const ContentEditor = () => {

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState<IGetBlogsAdmin | null>(null);
    const [updateblogs, setUpdateBlogs] = useState<BlogUpdate>({ id: '', blog_title: '', blog_image: '', blog_summary: '', blog_det: '', url: '', publish_date: '' });
    const [blogDetailsUpdateMutation] = useBlogDetailsUpdateMutation();

    const [Draftblogs, setDraftBlogs] = useState<BlogDraft>({ id: '', blog_title: '', blog_image: '', blog_summary: '', blog_det: '', url: '' });
    const [blogDetailsDraftMutation] = useBlogDetailsDraftMutation();

    const [Publishblogs, setPublishBlogs] = useState<BlogPublish>({ id: '', blog_title: '', blog_image: '', blog_summary: '', blog_det: '', url: '' });
    const [blogDetailsPublishMutation] = useBlogDetailsPublishMutation();

    const [ContentGeneration, setContentGeneration] = useState<IGenerateContent>({ cat: '', title: '', prompt: '' });
    const [generateBlogsMutation] = useGenerateBlogsMutation();
    const [delBlogs, setDelBlogs] = useState<IDeleteBlog | undefined>(undefined);



    const [openAddNew, setOpenAddNew] = useState(false);
    const [blogPreviewValues, setBlogPrevValues] = useState<BlogPreviewResponseStructMsg | null>(null);
    const [sendBlogId, setSendBlogId] = useState<BlogPreviewResponseStructMsg | null>(null);


    const [blogs, setBlogs] = useState<IGetBlogsAdmin[]>([]);
    const [blogId, setBlogId] = useState("");
    const [status, setStatus] = useState("");

    console.log(blogs);

    const [selectedBlogTitle, setSelectedBlogTitle] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; //Number of blogs per page
    const getBlogsCount = useBlogsCountQuery({cat:"",query:""});
    console.log(getBlogsCount);

    const getBlogs = useGetBlogDetailsAdminQuery({ limitval: pageSize, skipval: (currentPage - 1) * pageSize, query: searchQuery });
    console.log(getBlogs);
    useEffect(() => {
        if (getBlogs && getBlogs.data) {
            const updatedBlogs = getBlogs.data.map((blog, index) => ({
                ...blog,
                serialNumber: (currentPage - 1) * pageSize + index + 1,
            }));
            // Apply filtering if a blog title is selected
            if (selectedBlogTitle) {
                const filtered = updatedBlogs.filter(blog => blog.blog_title === selectedBlogTitle);
                setBlogs(filtered);
            } else {
                setBlogs(updatedBlogs);
                console.log(blogs);
            }
        }
    }, [getBlogs, currentPage, selectedBlogTitle]);

    //options for dropdown
    const dropdownList = blogs.map((blog) => blog.blog_title || blog.blog_title_draft);


    // Pagination
    let totalPages = Math.ceil((getBlogsCount?.data ?? 0) / pageSize);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    }

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
    }

    const generatePageNumbers = (totalPages: number, currentPage: number): (number | '...')[] => {
        const pageNumbers: (number | '...')[] = [];
        for (let i = 1; i <= totalPages; i++) {
            if (i === 1 || i === totalPages || Math.abs(i - currentPage) < 3) {
                pageNumbers.push(i);
            } else if (pageNumbers[pageNumbers.length - 1] !== '...') {
                pageNumbers.push('...');
            }
        }
        return pageNumbers;
    };

    const pageNumbers = generatePageNumbers(totalPages, currentPage);
    //


    //Edit functionality in Listing
    const handleEditClick = (blog: IGetBlogsAdmin) => {
        debugger
        setSelectedBlog(blog);
        setOpen(true);

        const mode = blog.mode || 'live';




        if (mode === 'live') {
            setUpdateBlogs({
                id: blog._id,
                blog_title: blog.blog_title,
                blog_image: blog.blog_image_string,
                blog_summary: blog.blog_summary,
                blog_det: blog.blog_det,
                url: blog.url,
                publish_date: blog.publish_date
            });
            console.log(updateblogs);
        }
        else {
            setUpdateBlogs({
                id: blog._id,
                blog_title: blog.blog_title_draft,
                blog_image: blog.blog_image_path_draft,
                blog_summary: blog.blog_summary_draft,
                blog_det: blog.blog_det_draft,
                url: blog.url_draft,
                publish_date: blog.publish_date
            });
            console.log(updateblogs);
        }
    };


    //popup datas update functionality
    /*  const handleUpdate = async () => {
         try {
             const updatedBlog = await blogDetailsUpdateMutation({
                 id: selectedBlog?._id || '',
                 blog_title: updateblogs.blog_title,
                 blog_image: updateblogs.blog_image,
                 blog_summary: updateblogs.blog_summary,
                 blog_det: updateblogs.blog_det,
                 url: updateblogs.url,
             });
             toast.success('Blog saved to draft Successfully!!');
             setOpen(false);
         } catch (error) {
             console.error('Error updating blog:', error);
         }
     }; */

    const handlesaveDraft = async () => {
        try {
            const updatedBlog = await blogDetailsDraftMutation({
                id: selectedBlog?._id || '',
                blog_title: updateblogs.blog_title,
                blog_image: updateblogs.blog_image,
                blog_summary: updateblogs.blog_summary,
                blog_det: updateblogs.blog_det,
                url: updateblogs.url,
            });
            toast.success('Blog saved to draft Successfully!!');
            setOpen(false);
        } catch (error) {
            console.error('Error updating blog:', error);
        }
    };

    const handlePublished = async () => {
        try {
            const updatedBlog = await blogDetailsPublishMutation({
                id: selectedBlog?._id || '',
                blog_title: updateblogs.blog_title,
                blog_image: updateblogs.blog_image,
                blog_summary: updateblogs.blog_summary,
                blog_det: updateblogs.blog_det,
                url: updateblogs.url,
            });
            toast.success('Blog Published Successfully!!');
            setOpen(false);
        } catch (error) {
            console.error('Error updating blog:', error);
        }
    };


    useEffect(() => {
        console.log(sendBlogId);
        console.log(status);
    }, [blogPreviewValues, sendBlogId, status])

    const encodedContent = encodeURIComponent(ContentGeneration.prompt).replace(/%2B/g, '+');


    const [isLoading, setIsLoading] = useState(false);

    const toggleLoading = (value: boolean) => {
        setIsLoading(value);
    };

    const handleGenerateContent = async () => {
        try {
            toggleLoading(true);
            const updatedBlog: any = await generateBlogsMutation({
                cat: ContentGeneration.cat,
                title: ContentGeneration.title,
                prompt: encodedContent

            });
            const blogPrevValues = updatedBlog.data.msg;
            const blogId = updatedBlog.data.blogid;
            const responsestatus = updatedBlog.data.status;
            setBlogId(blogId);
            setBlogPrevValues(blogPrevValues);
            setSendBlogId(blogId)
            setStatus(responsestatus);
            toast.success('Succesfully Generated a Content');
            toggleLoading(false);
            // setOpenAddNew(false)
        } catch (error) {
            console.error('Error generating blog:', error);
            toggleLoading(false);
        }
    };

    const handleSave = async (blogId: string) => {
        try {
            if (blogPreviewValues) {
                const generatedBlog = await blogDetailsPublishMutation({
                    id: blogId,
                    blog_title: blogPreviewValues.blog_title_draft,
                    blog_image: blogPreviewValues.blog_image_path_draft,
                    blog_summary: blogPreviewValues.blog_summary_draft,
                    blog_det: blogPreviewValues.blog_det_draft,
                    url: blogPreviewValues.url_draft,
                });
                toast.success('Saved Successfully!!');
                setOpenAddNew(false);

            } else {
                console.error('blogPreviewValues is null or undefined');
            }
        } catch (error) {
            console.error('Error updating blog:', error);
        }
    }




    const [checked, setChecked] = useState(false);



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    }

    const staticTypeOptions = ['Blogs']

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6] }],
            // [{ 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link'],
            [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],  // Include alignment options
        ],
    };



    const handleAddNew = () => {
        setOpenAddNew(true);
        setBlogPrevValues({
            blog_det_draft: '',
            blog_image_path_draft: '',
            blog_image_string: '',
            blog_summary_draft: '',
            blog_title_draft: '',
            cat: '',
            image_updated: '',
            mode: '',
            url_draft: '',
            blogid: '',
        })

    }

    
    const [deleteBlogMutation] = useDeleteBlogMutation();

    const handleDelete = async (blog_id: string) => {
        try {
            const response = await deleteBlogMutation({ blog_id });
            console.log(response);
            if ('data' in response) {
                const datas = response.data;
                if (datas.status === 'success') {
                    toast.success('Blog Deleted successfully');

                    setBlogs(prevBlogs => {
                        const updatedBlogs = prevBlogs.filter(blog => blog._id !== blog_id);
    
                        // Update serial numbers for the remaining blogs
                        return updatedBlogs.map((blog, index) => ({
                            ...blog,
                            serialNumber: index + 1,
                        }));
                    });
                }
                else {
                    toast.error('Failed to delete blog');
                }
            } else {
                toast.error('Failed to delete blog');
            }          
        } catch (error) {
            console.error('Error deleting blog', error);
            toast.error('An error occurred while deleting the blog');
        }
    }


    return (
        <>

            <div className="body-17-1" >
                <Box
                    paddingY={5}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Typography
                        textAlign="center"
                        variant="h4"
                        sx={{
                            background:
                                "-webkit-linear-gradient(139deg, #036AB7 0%, #49A4EA 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            letterSpacing: "-0.8px",
                            fontWeight: "bold",
                        }}
                    >
                        Content Management
                    </Typography>
                    <Autocomplete
                        options={dropdownList}
                        sx={{ width: 680, paddingTop: 5, "& label": { color: "black" }, "& input": { className: 'red-border1' } }}
                        value={selectedBlogTitle || ''}
                        onChange={(event, newValue) => {
                            setSelectedBlogTitle(newValue || '');
                            setSearchQuery(newValue || '');
                        }}
                        renderInput={(params) => (
                            <TextField
                                style={{ border: '1px solid #CBCBCB' }}
                                {...params}
                                // label="Type & search or select prompt from list"
                                placeholder="Type & search or select prompt from list"
                                size="small"
                                InputLabelProps={{
                                    className: 'red-label',
                                }}
                            />
                        )}
                    />
                </Box>
                <Divider />

                <Stack display="block" width="100%" padding="1%">
                    <LoadingButton
                        startIcon={
                            <SvgIcon>
                                <AddIcon />
                            </SvgIcon>
                        }
                        sx={{
                            background:
                                "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                            marginLeft: 2,
                            marginBottom: 2,
                            color: "white",
                            paddingX: "20px",
                            paddingY: "12px",
                            borderRadius: "4px",
                            width: "fit-content",
                            float: "right"
                        }}
                        // onClick={() => (setOpenAddNew(true))}
                        onClick={handleAddNew}
                    >

                        Add New

                    </LoadingButton>
                </Stack>

                <TableContainer component={Paper} sx={{ margin: '1%', width: '98%' }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead style={{ backgroundColor: ' #F2F6FA', border: "gray" }}>
                            <TableRow>
                                <StyledTableCell style={{ border: "gray" }} align="center">S.No</StyledTableCell>
                                <StyledTableCell style={{ border: "gray" }} align="center">TITLE</StyledTableCell>
                                <StyledTableCell style={{ border: "gray" }} align="center">DATE PUBLISHED</StyledTableCell>
                                <StyledTableCell style={{ border: "gray" }} align="center">SEO SCORE</StyledTableCell>
                                {/* <StyledTableCell style={{ border: "gray" }} align="center">PLAGIARISM SCORE</StyledTableCell> */}
                                <StyledTableCell style={{ border: "gray" }} align="center">TOTAL VIEWS</StyledTableCell>
                                <StyledTableCell style={{ border: "gray" }} align="center">RATING</StyledTableCell>
                                <StyledTableCell style={{ border: "gray" }} align="center">ACTION</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {blogs.map((blog, index) => (
                                <StyledTableRow key={blog._id} className={index % 2 === 0 ? 'blackAndWhite' : ''}>
                                    <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{blog.serialNumber}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row" align="left" style={{ color: 'black', border: "darkgray" }}>
                                        {blog.blog_title || blog.blog_title_draft}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{blog.publish_date.split("T")[0]}</StyledTableCell>
                                    <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{blog.seo_score}</StyledTableCell>
                                    {/* <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{'1'}</StyledTableCell> */}
                                    <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{blog.total_views}</StyledTableCell>
                                    <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{blog.rattings}</StyledTableCell>
                                    <StyledTableCell align="center" style={{ cursor: 'pointer', border: "darkgray" }}><DriveFileRenameOutlineIcon style={{ color: 'dodgerblue' }} onClick={() => handleEditClick(blog)} />&nbsp;<span><DeleteOutlinedIcon style={{ color: 'red' }} onClick={() => handleDelete(blog._id)}></DeleteOutlinedIcon></span></StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div>&nbsp;</div>
                <div style={{ textAlign: 'center' }}>
                    <LoadingButton
                        startIcon={
                            <SvgIcon>
                                <KeyboardArrowLeft />
                            </SvgIcon>
                        }

                        sx={{
                            paddingRight: '15px'
                        }}
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                    >

                    </LoadingButton>

                    {/* <span>{currentPage}</span> */}
                    {pageNumbers.map((page, index) => (
                        <span key={index} style={{ padding: '0 5px' }}>
                            <span
                                style={{
                                    cursor: page === '...' ? 'default' : 'pointer',
                                    fontWeight: page === currentPage ? 'bold' : 'normal'
                                }}
                                onClick={() => {
                                    if (typeof page === 'number') setCurrentPage(page);
                                }}
                            >
                                {page}
                            </span>
                        </span>
                    ))}

                    <LoadingButton
                        startIcon={
                            <SvgIcon>
                                <KeyboardArrowRight />
                            </SvgIcon>
                        }

                        sx={{
                            paddingLeft: '35px'
                        }}
                        onClick={handleNextPage}
                        disabled={blogs.length < pageSize}
                    >

                    </LoadingButton>
                </div>
                <div>
                    &nbsp;
                </div>


                {/* Edit Popup */}
                <div>
                    <Modal open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description">
                        <Box sx={{
                            position: "absolute" as "absolute",
                            top: "50%",
                            left: "50%",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            transform: "translate(-50%, -50%)",
                            width: /* isMobile ? "95%" : */ 1000,
                            bgcolor: "white",
                            border: "none",
                            // boxShadow: 24,
                            pt: 2,
                            px: 4,
                            pb: 3,
                            height: '98%',
                            overflow: 'auto'
                        }}>
                            <Stack width="100%">
                                <Grid container>
                                    <Grid item xs={12} lg={6}>
                                        <Typography variant='h5' fontWeight="bold" style={{ color: 'black' }}>Edit Content</Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={6} style={{ display: 'flex', justifyContent: 'flex-end', padding: '1%' }}>
                                        <Button
                                            variant="outlined"
                                            sx={{ width: 200, paddingY: 1, marginRight: '2%', fontSize: '12px' }}
                                            onClick={handlesaveDraft}
                                        >
                                            Save As Draft
                                        </Button>

                                        <Button
                                            variant="contained"
                                            sx={{ width: 200, paddingY: 1, marginRight: '2%', fontSize: '12px' }}
                                            onClick={handlePublished}
                                        >
                                            Save & Publish
                                        </Button>

                                        <Button
                                            variant="contained"
                                            sx={{ width: 200, paddingY: 1, marginRight: '2%', fontSize: '12px' }}
                                            onClick={() => setOpen(false)}
                                        >
                                            Close
                                        </Button>



                                    </Grid>
                                </Grid>

                                <Divider style={{ height: '2px', backgroundColor: '#CBCBCB' }} />

                                <Grid container>
                                    <Grid item xs={12} lg={8} style={{ borderRight: "1px solid #CBCBCB", padding: '1%' }}>
                                        <Stack padding="1%">
                                            <Typography style={{ color: 'black' }}>Blog Title</Typography>
                                            <TextField
                                                InputProps={{
                                                    style: customInputStyle,
                                                }}
                                                style={{ width: '100%' }}
                                                value={/* selectedBlog ? selectedBlog.blog_title : '' */updateblogs.blog_title}
                                                onChange={(e) => setUpdateBlogs({ ...updateblogs, blog_title: e.target.value })}></TextField>
                                        </Stack>
                                        <div style={{ display: 'none' }}>
                                            <Stack padding="1%">
                                                <Typography style={{ color: 'black' }}>Blog Image</Typography>
                                                <TextField
                                                    InputProps={{
                                                        style: customInputStyle,
                                                    }}
                                                    style={{ width: '100%', display: 'none' }} value={/* selectedBlog ? selectedBlog.blog_image_string : '' */updateblogs.blog_image}
                                                    onChange={(e) => setUpdateBlogs({ ...updateblogs, blog_image: e.target.value })}></TextField>
                                            </Stack>
                                        </div>
                                        <Stack padding="1%">
                                            <Typography style={{ color: 'black' }}>Blog Summary</Typography>
                                            <TextField
                                                InputProps={{
                                                    style: customInputStyle,
                                                }}
                                                style={{ color: 'black', width: '100%' }}
                                                value={/* selectedBlog ? selectedBlog.blog_summary : '' */updateblogs.blog_summary}
                                                onChange={(e) => setUpdateBlogs({ ...updateblogs, blog_summary: e.target.value })}></TextField>
                                        </Stack>
                                        <Stack padding="1%">
                                            <Typography style={{ color: 'black' }}>Blog Content</Typography>
                                            <div style={{ height: '300px', overflow: 'auto', border: '1px solid #ccc' }}>
                                                <ReactQuill /* fullWidth multiline rows={8} */
                                                    style={{ height: '85%', color: 'black' }}
                                                    value={/* selectedBlog ? selectedBlog.blog_det : '' *//* decodedBlogDet */updateblogs.blog_det}
                                                    onChange={(value: any) => setUpdateBlogs({ ...updateblogs, blog_det: value })}
                                                    theme='snow'
                                                    modules={modules}
                                                />
                                            </div>
                                        </Stack>
                                        <Stack padding="1%">
                                            <Typography style={{ color: 'black' }}>Blog URL</Typography>
                                            <Grid container>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        InputProps={{
                                                            style: customInputStyle,
                                                        }}
                                                        style={{ width: '100%' }} value={/* selectedBlog ? selectedBlog.url : '' */updateblogs.url}
                                                        onChange={(e) => setUpdateBlogs({ ...updateblogs, url: e.target.value })}
                                                    ></TextField>
                                                </Grid>
                                                <Grid item xs={4} display={"flex"} justifyContent={"flex-end"}>
                                                    <Button
                                                        startIcon={
                                                            <SvgIcon>
                                                                <EyeIcon />
                                                            </SvgIcon>
                                                        }

                                                        sx={{
                                                            background:
                                                                "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                                            marginLeft: 5,
                                                            color: "white",
                                                            paddingX: "20px",
                                                            paddingY: "12px",
                                                            borderRadius: "4px",
                                                            width: "fit-content",
                                                        }}

                                                    > {/* View Blog */}
                                                        <Link to={`/PreviewBlogs/${updateblogs.id}`} target="_blank" rel="noopener noreferrer" style={{ color: '#FFF', textDecoration: 'none' }}>Preview Blog</Link>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} lg={4} style={{ padding: '1%' }}>
                                        <Stack padding="1%">
                                            <Typography variant="h6" fontWeight="bold" style={{ color: 'black' }}>Article Details</Typography>
                                        </Stack>
                                        <Stack padding="1%">
                                            <Typography style={{ color: 'black' }}>Type</Typography>
                                            <Autocomplete
                                                options={staticTypeOptions}
                                                sx={{ border: '#CBCBCB' }}
                                                renderInput={(params) => (
                                                    <TextField

                                                        style={{ width: '100%', border: '1px solid #CBCBCB' }}
                                                        {...params}
                                                        placeholder="Blogs"
                                                        // label="Blogs"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <div style={{ display: 'none' }}>
                                            <Stack padding="1%">
                                                <Typography style={{ color: 'black' }}>Tags</Typography>
                                                <TextField
                                                    InputProps={{
                                                        style: customInputStyle,
                                                    }}
                                                    style={{ width: '100%' }}></TextField>
                                            </Stack>
                                        </div>
                                        <Stack padding="1%">
                                            <Typography style={{ color: 'black' }}>Date of Published</Typography>
                                            <TextField
                                                InputProps={{
                                                    style: customInputStyle,
                                                }}
                                                value={updateblogs.publish_date}
                                                style={{ width: '100%' }}>{/* updateblogs?.publish_date.split("T")[0] */}</TextField>
                                        </Stack>
                                        <div style={{ display: 'none' }}>
                                            <Stack padding="1%">
                                                <Typography style={{ color: 'black' }}>Author</Typography>
                                                <TextField
                                                    InputProps={{
                                                        style: customInputStyle,
                                                    }}
                                                    style={{ width: '100%' }}></TextField>
                                            </Stack>
                                        </div>



                                    </Grid>
                                </Grid>

                            </Stack>
                        </Box>
                    </Modal>
                </div>
                {/*  */}


                {/* Add New Popup */}
                <div>

                    <Modal open={openAddNew}
                        onClose={() => setOpenAddNew(false)}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <>
                            {isLoading && (
                                <Box position="absolute">
                                    <Backdrop
                                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={true}
                                    >
                                        <Box>
                                            <MagicWand />

                                            <Typography textAlign="center">Generating Content</Typography>

                                        </Box>
                                    </Backdrop>
                                </Box>
                            )}


                            <Box sx={{
                                position: "absolute" as "absolute",
                                top: "50%",
                                left: "50%",
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                transform: "translate(-50%, -50%)",
                                width: /* isMobile ? "95%" : */ 'auto',
                                bgcolor: "white",
                                border: "1px solid #000",
                                boxShadow: 24,
                                pt: 2,
                                px: 4,
                                pb: 3,
                                height: '98%',
                                overflow: 'auto',
                            }}>

                                <Stack spacing={5}>
                                    <Stack flexDirection="row">
                                        <Typography
                                            fontWeight="bold"
                                            variant="h6"
                                            color="text.secondary"
                                            flexGrow={1}
                                            fontSize={25}
                                            style={{ color: 'black' }}                                >
                                            Generate New Content
                                        </Typography>
                                        <Stack flexDirection="row" gap={3}>
                                            {status === 'success' ?
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        sx={{ width: 90, paddingY: 1 }}
                                                        onClick={handleAddNew}
                                                    >
                                                        New
                                                    </Button>
                                                    <LoadingButton
                                                        variant="contained"
                                                        sx={{ width: 90, paddingY: 1 }}
                                                        // loading={updateState.isLoading}
                                                        type="submit"
                                                        onClick={() => handleSave(blogId)}
                                                    >
                                                        Save
                                                    </LoadingButton>
                                                </>
                                                : null}
                                            <LoadingButton
                                                variant="contained"
                                                sx={{ width: 90, paddingY: 1 }}
                                                // loading={updateState.isLoading}
                                                type="submit"
                                                onClick={() => setOpenAddNew(false)}
                                            >
                                                Close
                                            </LoadingButton>
                                        </Stack>
                                    </Stack>
                                    <Divider style={{ marginTop: '15px', height: '2px', backgroundColor: '#CBCBCB' }} />
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography style={{ color: 'black' }} fontWeight="bold" variant="h6" color="text.secondary" flexGrow={1} fontSize={20}>
                                                Settings
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <div>
                                        <Grid container>

                                            <Grid item xs={12}>
                                                <Stack flexDirection="row" gap={2}>
                                                    <Box alignItems="center" gap={1} >
                                                        <InputLabel id="product-label" style={{ fontWeight: 'normal', color: 'black' }}>Language</InputLabel>
                                                        <TextField
                                                            InputProps={{
                                                                style: customInputStyle,
                                                            }}
                                                            /* InputLabelProps={{
                                                                style: {
                                                                  color: 'black', // Set the desired color for the label
                                                                },
                                                              }} */
                                                            sx={{
                                                                width: {
                                                                    lg: '130px',
                                                                    xs: '100px'
                                                                }
                                                            }}
                                                            size="small"
                                                            select
                                                        // label="Language"
                                                        // ... other props
                                                        >
                                                            <MenuItem value="english">English</MenuItem>
                                                            <MenuItem value="spanish">Spanish</MenuItem>
                                                            <MenuItem value="french">French</MenuItem>
                                                            {/* ... menu items */}
                                                        </TextField>

                                                    </Box>
                                                    <Box alignItems="center" gap={1}>
                                                        <InputLabel id="module-label" style={{ fontWeight: 'normal', color: 'black' }}>Products</InputLabel>
                                                        <TextField
                                                            InputProps={{
                                                                style: customInputStyle,
                                                            }}
                                                            size="small"
                                                            select
                                                            sx={{
                                                                width: {
                                                                    lg: '130px',
                                                                    xs: '100px'
                                                                }
                                                            }}

                                                            // label="Products"
                                                            onChange={(e) => setContentGeneration({ ...ContentGeneration, cat: e.target.value })}

                                                        // ... other props
                                                        >
                                                            <MenuItem value="Any Code">Any Code</MenuItem>
                                                            <MenuItem value="Smart Contract">Smart Contract</MenuItem>
                                                        </TextField>
                                                        {/* ... menu items */}


                                                    </Box>
                                                    <Box alignItems="center" gap={1}>
                                                        <InputLabel id="prompt-name-label" style={{ fontWeight: 'normal', color: 'black' }}>Date</InputLabel>
                                                        <Box alignItems="center" display='flex'>

                                                            <TextField
                                                                InputProps={{
                                                                    style: customInputStyle,
                                                                }}
                                                                style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }} size="small" type="date"></TextField>
                                                            <TextField
                                                                InputProps={{
                                                                    style: customInputStyle,
                                                                }}
                                                                style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                                                                size="small"
                                                                type="date"
                                                            // labelId="order-label"
                                                            // ... other props
                                                            />

                                                        </Box>
                                                    </Box>
                                                    <Box alignItems="center" gap={1}>
                                                        <InputLabel id="order-label">&nbsp;</InputLabel>

                                                    </Box>
                                                    <Box alignItems="center" gap={1}>
                                                        <InputLabel id="order-label" style={{ fontWeight: 'normal', color: 'black' }}>Auto Generate</InputLabel>
                                                        <FormControlLabel
                                                            control={<Switch checked={checked} onChange={handleChange} color="primary" />}
                                                            label={checked ? <span className="blackLabel">On</span> : <span className="blackLabel">Off</span>}
                                                        />

                                                    </Box>
                                                </Stack>
                                            </Grid>
                                        </Grid>

                                    </div>


                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography
                                                color="text.secondary"
                                                variant="h6"
                                                // fontWeight={400}
                                                fontWeight="bold"
                                                fontSize={20}
                                                style={{ color: 'black' }}
                                            >
                                                Text Prompts
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                fontSize={15}
                                                fontWeight={200}
                                                style={{ color: 'black' }}
                                            >
                                                Type prompt to create blogs
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            gap={2}
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <TextField
                                                // {...register("module_description")}
                                                InputProps={{
                                                    style: customInputStyle,
                                                }}
                                                placeholder="Title goes here"
                                                onChange={(e) => setContentGeneration({ ...ContentGeneration, title: e.target.value })}


                                            />
                                            <TextField

                                                InputProps={{
                                                    style: customInputStyle,
                                                }}
                                                // {...register("module_description")}
                                                placeholder="Type text prompt here"
                                                onChange={(e) => setContentGeneration({ ...ContentGeneration, prompt: e.target.value })}

                                            />
                                            <ReactQuill
                                                style={{ height: '300px', color: 'black' }}
                                                /* InputProps={{
                                                    style: customInputStyle,
                                                }} */
                                                placeholder="Blog preview shown here"
                                                value={blogPreviewValues?.blog_det_draft}
                                                theme='snow'
                                                modules={modules}

                                            />
                                        </Grid>
                                    </Grid>

                                    <Stack display="block">
                                        <LoadingButton

                                            startIcon={
                                                <SvgIcon>
                                                    <WishIcon />
                                                </SvgIcon>
                                            }

                                            sx={{
                                                background:
                                                    "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                                marginLeft: 5,
                                                color: "white",
                                                paddingX: "20px",
                                                paddingY: "12px",
                                                borderRadius: "4px",
                                                width: "fit-content",
                                                float: "right",
                                                marginTop: '4%'
                                            }}
                                            onClick={handleGenerateContent}
                                        > {/* {isLoading ? <MagicWand /> : "Generate Content"} */}
                                            Generate Content
                                        </LoadingButton>
                                        {/* <LoadingButton
                startIcon={
                  <SvgIcon>
                    <WishIcon />
                  </SvgIcon>
                }

                sx={{
                  background:
                    "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                  marginLeft: 5,
                  color: "white",
                  paddingX: "20px",
                  paddingY: "12px",
                  borderRadius: "4px",
                  width: "fit-content",
                  float: "right"
                }}
                onClick={handleEditClick}
              > Edit

              </LoadingButton> */}
                                    </Stack>
                                    {/* <Grid container>
                                    <Grid item xs={2}>
                                        <Typography
                                            color="text.secondary"
                                            variant="h6"
                                            fontWeight="bold"
                                            fontSize={20}
                                            style={{ color: 'black' }}
                                        >
                                            Image Prompt
                                        </Typography>

                                        <Typography
                                            variant="h6"
                                            fontSize={15}
                                            fontWeight={200}
                                            style={{ color: 'black' }}
                                        >
                                            Type prompt to create images
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10} gap={2}
                                        display="flex"
                                        flexDirection="column">
                                        <TextField
                                            InputProps={{
                                                style: customInputStyle,
                                            }}
                                        />

                                        <TextField
                                            InputProps={{
                                                style: customInputStyle,
                                            }} fullWidth
                                            multiline
                                            rows={12}
                                            placeholder="Image preview shown here"

                                        />
                                    </Grid>
                                </Grid> */}
                                    {/* 
                                <Stack display="block">
                                    <LoadingButton
                                        startIcon={
                                            <SvgIcon>
                                                <WishIcon />
                                            </SvgIcon>
                                        }

                                        sx={{
                                            background:
                                                "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                            marginLeft: 5,
                                            color: "white",
                                            paddingX: "20px",
                                            paddingY: "12px",
                                            borderRadius: "4px",
                                            width: "fit-content",
                                            float: "right"
                                        }}
                                    >Regenerate Image

                                    </LoadingButton>
                                </Stack>
 */}
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography
                                                color="text.secondary"
                                                variant="h6"
                                                fontWeight={400}
                                                style={{ color: 'black' }}
                                            >
                                                CTA Widget
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Stack flexDirection="row" gap={1}>
                                                <TextField style={{ width: '100%' }}
                                                    // {...register("input_box_title")}
                                                    placeholder="Add a URL here"
                                                    InputProps={{
                                                        style: customInputStyle,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LinkIcon style={{ color: 'black' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    value={blogPreviewValues?.url_draft}

                                                // value={blogPreviewValues.url_draft}
                                                /* error={!!errors["input_box_title"]}
                                                helperText={errors["input_box_title"]?.message} */
                                                />
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={3} display={"flex"} justifyContent={"flex-end"}>
                                            {status === 'success' ?
                                                <LoadingButton
                                                    startIcon={
                                                        <SvgIcon>
                                                            <EyeIcon />
                                                        </SvgIcon>
                                                    }

                                                    sx={{
                                                        background:
                                                            "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                                        marginLeft: 5,
                                                        color: "white",
                                                        paddingX: "20px",
                                                        paddingY: "12px",
                                                        borderRadius: "4px",
                                                        width: "fit-content",
                                                    }}

                                                > {/* View Blog */}
                                                    <Link to={`/PreviewBlogs/${sendBlogId}`} target="_blank" rel="noopener noreferrer" style={{ color: '#FFF', textDecoration: 'none' }}>Preview Blog</Link>
                                                </LoadingButton>
                                                : null}
                                        </Grid>
                                    </Grid>

                                    <TextField
                                        // {...register("id")}
                                        type="hidden"
                                        sx={{ display: "none" }}
                                    />
                                </Stack>
                            </Box>
                        </>
                    </Modal>
                </div>
                {/*  */}
            </div>
        </>
    );
};

export default ContentEditor;
