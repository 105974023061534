import { Box, Typography, Container, Divider, SvgIcon, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowRight } from "@mui/icons-material";
import image_17 from "../assets/img/image17.png";


const The_AI_Advantage = () => {

  const navigate = useNavigate();

  const handleLearnMore = () =>{
    navigate('/codegenie/smart_contracts');
  }

    return (
      <>
      
        <Container maxWidth="lg">
          <Box py={5}>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img className="ellipse-18" alt="Ellipse" src={image_17} title="Code Genie: The AI Advantage: Code Genie's Mastery Over Traditional Auditing Methods"/></div> <br></br>  <br></br>
            The AI Advantage: Code Genie's Mastery Over Traditional Auditing Methods
            </Typography>
            <Typography textAlign="justify">
            In the intricate landscape of blockchain technology, smart contracts stand as pillars of innovation, offering a transparent, tamper-proof way to codify and automate agreements. As powerful as they might be, these contracts are not immune to human errors, and any mistake can lead to devastating financial consequences. Enter the world of smart contract auditing, where the focus isn't just on detecting problems but predicting them, mitigating them, and ensuring the seamless execution of every agreement. While traditional auditing methods have made significant strides, they are increasingly being overshadowed by the prowess of AI-driven tools, with Code Genie leading the charge.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            The Limitations of Traditional Auditing
            </Typography>
            <Typography textAlign="justify">
            Historically, smart contract audits were performed by human experts—skilled, yes, but not infallible. Here are some limitations inherent to traditional auditing:
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2}textAlign="justify">
            Time-Consuming
            </Typography>
            <Typography textAlign="justify">
            Manual review of code, especially complex contracts, can take days or even weeks, delaying project timelines.

            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Human Error
            </Typography>
            <Typography textAlign="justify">
            No matter how meticulous, human auditors can miss nuances, especially in dense and intricate code structures
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Lack of Scalability
            </Typography>
            <Typography textAlign="justify">
            As the blockchain ecosystem grows, the sheer number of contracts to review exceeds the capacity of manual review teams
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Code Genie's AI-driven Approach: A New Paradigm
            </Typography>
            <Typography textAlign="justify">
            Where traditional methods waver, Code Genie, with its integration of advanced AI, sets a new benchmark. Here's how:
            </Typography>
    
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Sculpting the Future of Blockchain
            </Typography>
            <Typography textAlign="justify">
            As blockchain networks evolve, diversify, and scale, the demand for robust, reliable, and efficient smart contracts will surge. Code Genie, with its cutting-edge technology, is poised to lead this change, ensuring that as the world takes a deep dive into decentralization, 
             the waters are safe, transparent, and primed for innovation.  In essence, as decentralization reigns supreme, tools like Code Genie ensure that this reign is not just powerful but also poised, not just transformative but also trustworthy. As we look towards a future sculpted by blockchain, Code Genie promises a masterpiece of security, efficiency, and innovation.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Speed & Efficiency  
            </Typography>
            <Typography textAlign="justify">
            Leveraging machine learning algorithms, Code Genie can audit a contract in mere seconds, offering a swift turnaround without compromising on accuracy.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Deep Dive Analysis
            </Typography>
            <Typography textAlign="justify">
            Beyond surface-level issues, AI delves deep into the code, understanding its context, intent, and potential pitfalls. It doesn't just detect problems—it understands them
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Continuous Learning
            </Typography>
            <Typography textAlign="justify">
            : One of the hallmarks of AI is its ability to learn continuously. With every audit, Code Genie becomes smarter, refining its techniques and enhancing its accuracy
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Proactive Problem Solving
            </Typography>
            <Typography textAlign="justify">
            Rather than just pointing out issues, Code Genie offers real-time fixes, ensuring that developers can rectify issues promptly
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            The Future: Where AI and Blockchain Converge
            </Typography>
            <Typography textAlign="justify">
            As blockchain technologies become more mainstream, the demand for impeccable smart contracts will rise. Traditional methods, though valuable, might not meet the evolving challenges. Code Genie's AI-driven approach isn't just a solution for today—it's a vision for the future. It promises not just error-free contracts but optimized, efficient, and future-proof contracts that can stand the test of evolving blockchain landscapes
            </Typography>
            {/* <Link
              to="/signin"
              style={{
                display: "flex",
                marginTop: 40,
                textDecoration: "none",
                color: "black",
              }}
            >
              <SvgIcon sx={{ mr: 1 }}>
                <KeyboardBackspace />
              </SvgIcon>
              Back to log in
            </Link> */}
            <Button variant="contained" sx={{ mr: 2, float: 'right', margin: '8px' }}
          onClick={handleLearnMore} 
          >
            Learn More<KeyboardArrowRight/>
          </Button>
          </Box>
        </Container>
      </>
    );
  };
  
  export default The_AI_Advantage ;