/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const Bracketscurly2 = ({ color = "#FFEFC2", className }: Props): JSX.Element => {
  return (
    <svg
      className={`bracketscurly-2 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8 3.75C2 3.75 8 12 2 12C8 12 2 20.25 8 20.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M17 3.75C23 3.75 17 12 23 12C17 12 23 20.25 17 20.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

Bracketscurly2.propTypes = {
  color: PropTypes.string,
};
