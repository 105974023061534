import { Box, Typography, Container, Divider, SvgIcon } from "@mui/material";
import { Link } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import img54 from "../../assets/newimg/logo.png";
import { useState , useEffect } from "react";

import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";


const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>
const PrivacyPage = () => {
  const navigate = useNavigate();

  const handleLearnMore = () =>{
    navigate('/codegenie/smart_contracts');
  }
const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .unwrap()
      .then((response) => {
        console.log('news subscription=>', response);
          const status = response.status;
        
          console.log(status);
          if (status === 'success') {
            toast.success(response.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
      })
      .catch((error) => {
        toast.error(error?.data?.detail ?? 'Something went wrong!');
        console.error('Error sending query:', error);

      });
  }
  return (
    <>
      <Container maxWidth="lg">
      <nav className="navbar navbar-expand-md">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              {/* <img src="./img/logo.png" alt="" /> */}
              <img src={img54} className="img-fluid" alt="" />
              {/* <img src={logo} alt="" /> */}
  
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="">
                <i className="bi bi-list text-white" />
              </span>
            </button>



            
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                <li className="nav-item">
                  <a
                    className="nav-link nav_active"
                    style={{ color: "#4ba5eb", fontWeight: 800 }}
                    href="/"
                  >
                    Home
                  </a>
                </li>




                <li className="nav-item">
                  <a className="nav-link nav_simple" href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873">
                    Affiliate
                  </a>
                </li> 


              </ul>


              
              <div className="d-flex gap-4" role="search">
              <button className="button_leniar px-3" type="submit"  onClick={() => {
                navigate("/signup-AnyCode");
              }}>
                  Try Now
                </button>
                <button className="btn btn-outline-primary px-3"
                  onClick={() => {
                    navigate("/signin");
                  }}>Sign  in</button>
              </div>
            </div>
          </div>
        </nav> 
          <Box py={5}>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               Code Genie Privacy Policy </div>
          </Typography>
          <Typography>
            Last Updated: August 3, 2023
            <br />
            <br />
            OceSha, Inc. ("we," "our," or "us") is committed to protecting the privacy of all users of Code 
            Genie. This Privacy Policy explains how we collect, use, disclose, and safeguard your information 
            when you use Code Genie.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            1. Information We Collect
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            1.1 Personal Information
          </Typography>
          <Typography>
            We may collect personal information such as your name, email address, and other 
            identifying information that you voluntarily give to us when you register with Code Genie.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            1.2 Usage Information
          </Typography>
          <Typography>
            We may collect information about your usage of Code Genie, including the 
            code you analyze, the features you use, and other interactions with the service.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            1.3 Technical Information
          </Typography>
          <Typography>
            We may collect technical information such as your IP address, browser type, 
            device information, and other technical data related to your usage of Code Genie.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            2. How We Use Your Information
          </Typography>
          <Typography>
           We may use the information we collect to:
            <Box component="ul">
              <Box component="li">
                Provide and maintain Code Genie
              </Box>
              <Box component="li">
                Improve and personalize your experience
              </Box>
              <Box component="li">
                Communicate with you, including for customer service and marketing purposes
              </Box>
              <Box component="li">
                Comply with legal obligations
              </Box>
            </Box>
          </Typography>

          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            3. How We Share Your Information
          </Typography>
          <Typography>
            We do not sell or share your personal information with third parties for 
            their marketing purposes. We may share your information with:
            <Box component="ul">
              <Box component="li">
                Service providers and partners
              </Box>
              <Box component="li">
                Law enforcement, when required by law
              </Box>
              <Box component="li">
                Other users, as authorized by you
              </Box>
            </Box>
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            4. GDPR Compliance
          </Typography>
          <Typography>
            If you are a resident of the European Economic Area (EEA),
            you have certain data protection rights under the General 
            Data Protection Regulation (GDPR). These include:
            <Box component="ul">
              <Box component="li">
                Right to Access: You have the right to request access to your personal data.
              </Box>
              <Box component="li">
                Right to Rectification: You have the right to request correction of any inaccurate personal data.
              </Box>
              <Box component="li">
                Right to Erasure: You have the right to request deletion of your personal data, subject to certain exceptions.
              </Box>
              <Box component="li">
                Right to Restrict Processing: You have the right to request the restriction of processing of your personal data.
              </Box>
              <Box component="li">
                Right to Data Portability: You have the right to request transfer of your personal data to another entity.
              </Box>
              <Box component="li">
                Right to Object: You have the right to object to the processing of your personal data.
              </Box>
            </Box>
          </Typography>
          <Typography>
            To exercise these rights, please contact us at [<Link
                          to=""
                          onClick={(e) => {
                            window.location.href = `mailto:support@code-genie.ai`;
                            e.preventDefault();
                          }}
                          style={{
                            color: "#0168B5",
                          }}
                        >
                          support@code-genie.ai 
                        </Link>].
          </Typography>

          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            5. Security
          </Typography>
          <Typography>
            We implement reasonable security measures to protect your personal information.
            However, no electronic transmission or storage is 100% secure, and we cannot 
            guarantee absolute security.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            6. Third-Party Links
          </Typography>
          <Typography>
            Code Genie may contain links to third-party websites. We are not responsible 
            for the privacy practices of those websites, and this Privacy Policy does not apply to them.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            7. Children's Privacy
          </Typography>
          <Typography>
            Code Genie is not intended for children under the age of 13, and we do 
            not knowingly collect personal information from children under 13.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            8. Changes to This Privacy Policy
          </Typography>
          <Typography>
            We may update this Privacy Policy from time to time. We will notify you 
            of any changes by posting the new Privacy Policy on this page.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            9. Contact Us
          </Typography>
          <Typography>
          If you have any questions about these Terms, please contact Code Genie at <Link
                          to=""
                          onClick={(e) => {
                            window.location.href = `mailto:support@code-genie.ai`;
                            e.preventDefault();
                          }}
                          style={{
                            color: "#0168B5",
                          }}
                        >
                          support@code-genie.ai 
                        </Link>
          </Typography>
          <Divider sx={{ my: 5 }} />
          <Typography>
            By using Code Genie, you consent to the collection, 
            use, and sharing of your information as described in this 
            Privacy Policy. If you do not agree with the terms of this
            Privacy Policy, please do not use Code Genie.
          </Typography>
          <Link
            to="/signin"
            style={{
              display: "flex",
              marginTop: 40,
              textDecoration: "none",
              color: "white",
            }}
          >
            <SvgIcon sx={{ mr: 1 }}>
              <KeyboardBackspace />
            </SvgIcon>
            Back to log in
          </Link>
        </Box>
      </Container>
      <footer>
      <div className="py-5 mx-sm-0 mx-2 backgroundbg">
        <div className="container">
          <div className="row px-sm-0 px-2">
            <div className="col-lg-3 text-white text-lg-start text-center">
              <div>
                <div>
                  <img src={img54} alt="" />

                </div>
                <p className="fw_samibold">
                  OceSha/Code Genie Los Angeles, Ca 310-748-8901
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-white d-lg-none d-block  text-lg-start text-center">
              <div>
                <h6 className="f_heading mb-4">
                  Get news in your mailbox right away!
                </h6>
                <p className="fw_samibold">
                  In addition, stay in loop for our exclusive launches and limited
                  time deals.
                </p>
              </div>
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Search" />
                <button className="btn btn-primary" type="submit">
                  Subscribe
                </button>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4">
              <div>
              <div className="text-white">
                <h6 className="fw_samibold">Products</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a target="_blank" href="https://ai-course.code-genie.ai/courses/ArtificialIntelligence">AI Course</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://consulting.code-genie.ai/">AI Consulting</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.amazon.com/TECH-WARZ-Rise-Artificial-Intelligence-ebook/dp/B0C7BPRX9J/ref=sr_1_1?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-1">AI Book</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.amazon.com/TECH-WARZ-BLOCKCHAIN-CRYPTOCURRENCY-Money-ebook/dp/B0C83TGQSY/ref=sr_1_2?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-2">Blockchain Book</a>
                  </li>
                </ul>
              </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
              <div>
                <div className="text-white">
                  <h6 className="fw_samibold">Information</h6>
                  <ul
                    className="ps-0 mt-3 footer_dec"
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <a target="_blank" href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873">Affiliate</a>
                    </li>
                    <li>
                      <a target="_blank" href="/contactus">Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4  text-lg-start text-sm-end">
              <div>
                <div className="text-white">
                  <h6 className="fw_samibold">Support</h6>
                  <ul
                    className="ps-0 mt-3 footer_dec"
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <a target="_blank" href="/terms">Terms and Conditions</a>
                    </li>
                    <li>
                      <a target="_blank" href="/privacy">Privacy &amp; Cookie policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text-white d-lg-block d-none">
              <div>
                <h5 className="mb-4 f_heading">
                  Get news in your mailbox right away!
                </h5>
                <p className="fw_samibold">
                  In addition, stay in loop for our exclusive launches and limited
                  time deals.
                </p>
              </div>
              {/* News Subscribe */}
         <div className="input-group mb-3" onSubmit={handleSubmit(onSubmitHandler)}>
                    <input className={`form-control ${errors["email"] ? "error" : ""}`}
                      {...register("email")}
                      type="email"
                      placeholder="Enter your mail"
                      // error={!!errors["email"]}
                      // helperText={errors["email"]?.message}
                      onChange={(e) => setSubscribeVal({ ...subscribeVal, email: e.target.value })} />
                    <button className="btn btn-primary" type="submit" onClick={handleSubscribeNews}>
                      Subscribe
                    </button>
                    {errors["email"] && (
                      <span className="error-message">{errors["email"]?.message}</span>
                    )}
                  </div>
                  {/*  */}
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  );
};

export default PrivacyPage;
