import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Stack,
  Typography,
  FormControl,
  TextField,
  Box,
  Button,
  SvgIcon,
} from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { object, string, TypeOf } from "zod";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import BackSignin from "../../assets/back_signin.png";
import Logo from "../../logo.svg";
import VerifyPage from "./verify.page";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import {
  useForgotPasswordMutation,
  useResetPasswordMutation
} from "../../redux/api/authApi";

const forgotPassSchema = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
});

const resetSchema = object({
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters"),
  passwordConfirm: string().min(1, "Please confirm your password"),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "Passwords do not match",
});

export type ForgotPassInput = TypeOf<typeof forgotPassSchema>;
export type ResetInput = TypeOf<typeof resetSchema>;

const ForgotPage = () => {
  const [forgotPassUser, forgotPassState] = useForgotPasswordMutation();
  const [resetPassword, resetPassState] = useResetPasswordMutation();
  const [mailSent, mailSentState] = useState(false);
  const [resetPass, resetState] = useState(false);
  const [resetEmail, resetEmailState] = useState({});
  const navigate = useNavigate();

  const methods = useForm<ForgotPassInput>({
    resolver: zodResolver(forgotPassSchema),
  });

  const resetMethods = useForm<ResetInput>({
    resolver: zodResolver(resetSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const {
    register: resetRegister,
    handleSubmit: handleResetSubmit,
    formState: { errors: resetErrors },
  } = resetMethods;

  useEffect(() => {
    if (forgotPassState.isSuccess) {
      toast.success("Email sent successfully");
      //navigate("/signin");
      mailSentState(true);
    }
    if (forgotPassState.isError) {
      if (Array.isArray((forgotPassState.error as any).data.detail)) {
        (forgotPassState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((forgotPassState.error as any).data.detail);
      mailSentState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPassState]);

  useEffect(() => {
    if (resetPassState.isSuccess) {
      toast.success("Password reset successfully");
      navigate("/signin");
    }
    if (resetPassState.isError) {
      if (Array.isArray((resetPassState.error as any).data.detail)) {
        (resetPassState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((resetPassState.error as any).data.detail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPassState]);

  const onSubmitHandler: SubmitHandler<ForgotPassInput> = (values) => {
    //console.log(values);
    forgotPassUser(values);
    resetEmailState(values);
  };

  const onResetSubmitHandler: SubmitHandler<ResetInput> = (values) => {
    //console.log(values);
    resetPassword({...resetEmail,...values});
  };

  // Callback function to handle data received from the
  //child component
   const  handleCallback = (childData: boolean | ((prevState: boolean) => boolean)) => {
      // Update the name in the component's state
      resetState(childData);
      mailSentState(false);
  }

  return (
    <>
    { mailSent ? (
      <VerifyPage page="forgotPass" setPassCallback={handleCallback} />
    ) : (
      <Container maxWidth="xl">
        <Grid container p={4}>
          <Grid item xs={isMobile ? 12 : 6} position="relative">
          {!isMobile?(<img src={BackSignin} alt="Signup Background" />):null}
            <Typography
              position="absolute"
              bottom={103}
              left={32}
              variant="h4"
              width={580}
              fontWeight={600}
              color="white"
            >
              Audit, Fix, Refactor, Document any Code Within Seconds
            </Typography>
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 6}
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <Box
              position="absolute"
              
              top={50}
              display="flex"
              alignItems="center"
              gap={1}
              
            >
              <img width={44} height={44} src={Logo} alt="Logo" onClick={() => navigate('/')} />
              <Typography variant="h5" fontWeight={600} color="text.secondary" onClick={() => navigate('/')}>
                CodeGenie
              </Typography>
            </Box>
            { resetPass ? (
              <Box width={450}>
              <Typography variant="h4" mb={1} color="text.secondary">
                Reset password
              </Typography>
              <Typography mb={4}>
                Reset your password
              </Typography>
              <FormProvider {...resetMethods}>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleResetSubmit(onResetSubmitHandler)}
                >
                  <Stack gap={3}>
                    <FormControl fullWidth>
                      <Typography mb={1} color="text.secondary">
                        Password
                      </Typography>
                      <TextField
                        {...resetRegister("password")}
                        type="password"
                        placeholder="min 8 characters"
                        error={!!resetErrors["password"]}
                        helperText={resetErrors["password"]?.message}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography mb={1} color="text.secondary">
                        Confirm Password
                      </Typography>
                      <TextField
                        {...resetRegister("passwordConfirm")}
                        type="password"
                        placeholder="Same as previous"
                        error={!!resetErrors["passwordConfirm"]}
                        helperText={resetErrors["passwordConfirm"]?.message}
                      />
                    </FormControl>
                  </Stack>
                  <Button
                    type="submit"
                    fullWidth
                    sx={{
                      height: 56,
                      background:
                        "linear-gradient(90deg, #036AB7 0%, #4BA5EB 100%)",
                      color: "white",
                      mt: 4,
                    }}
                  >
                    Reset Password
                  </Button>
                </Box>
              </FormProvider>
              <Link
                to="/signin"
                style={{
                  display: "flex",
                  marginTop: 40,
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <SvgIcon sx={{ mr: 1 }}>
                  <KeyboardBackspace />
                </SvgIcon>
                Back to log in
              </Link>
            </Box>
            ) : (
            <Box width={450} mt={5}>
              <div className="mt-5">&nbsp;</div>
              <Typography variant="h4" mb={1} color="text.secondary" mt={5}>
                Forgot password?
              </Typography>
              <Typography mb={4}>
                No worries, we will send you reset instructions
              </Typography>
              <FormProvider {...methods}>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit(onSubmitHandler)}
                >
                <Stack gap={3}>
                  <FormControl fullWidth>
                    <Typography mb={1} color="text.secondary">
                      Email address
                    </Typography>
                    <TextField
                          {...register("email")}
                          type="email"
                          placeholder="Enter your email"
                          error={!!errors["email"]}
                          helperText={errors["email"]?.message}
                        />
                  </FormControl>
                </Stack>
                <Button
                  type="submit"
                  fullWidth
                  sx={{
                    height: 56,
                    background:
                      "linear-gradient(90deg, #036AB7 0%, #4BA5EB 100%)",
                    color: "white",
                    mt: 4,
                  }}
                >
                  Reset Password
                </Button>
                </Box>
              </FormProvider>
              <Link
                to="/signin"
                style={{
                  display: "flex",
                  marginTop: 40,
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <SvgIcon sx={{ mr: 1 }}>
                  <KeyboardBackspace />
                </SvgIcon>
                Back to log in
              </Link>
            </Box>
          )}
          </Grid>
        </Grid>
      </Container>
    )}
    </>
  );
};

export default ForgotPage;
