/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const Circlewavy1 = ({ color = "black", className }: Props): JSX.Element => {
  return (
    <svg
      className={`circlewavy-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5.59167 18.8906C4.72917 18.0281 5.30105 16.2187 4.86042 15.1594C4.4198 14.1 2.7323 13.1719 2.7323 12C2.7323 10.8281 4.40105 9.9375 4.86042 8.84063C5.3198 7.74375 4.72917 5.97187 5.59167 5.10938C6.45417 4.24688 8.26355 4.81875 9.32293 4.37813C10.3823 3.9375 11.3104 2.25 12.4823 2.25C13.6542 2.25 14.5448 3.91875 15.6417 4.37813C16.7385 4.8375 18.5104 4.24688 19.3729 5.10938C20.2354 5.97187 19.6636 7.78125 20.1042 8.84063C20.5448 9.9 22.2323 10.8281 22.2323 12C22.2323 13.1719 20.5636 14.0625 20.1042 15.1594C19.6448 16.2562 20.2354 18.0281 19.3729 18.8906C18.5104 19.7531 16.701 19.1813 15.6417 19.6219C14.5823 20.0625 13.6542 21.75 12.4823 21.75C11.3104 21.75 10.4198 20.0813 9.32293 19.6219C8.22605 19.1625 6.45417 19.7531 5.59167 18.8906Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

Circlewavy1.propTypes = {
  color: PropTypes.string,
};
