import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Divider,
  FormControl,
  TextField,
  SvgIcon,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useContactusMutation } from "../redux/api/authApi";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialTwitter,
  LoginSocialGithub,
  LoginSocialLinkedin,
  IResolveParams,
} from "reactjs-social-login";
import { LoadingButton } from "@mui/lab";
//import { useCookies } from "react-cookie";
import { setCookie, getCookie, removeCookie } from "../utils/cookies";
import BackSignin from "../../assets/back_signin.png";
import contactus from "../assets/contact-us.png";
import Logo from "../logo.svg"
import img54 from "../assets/newimg/logo.png";

import { ReactComponent as GoogleIcon } from "../../assets/ico_google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/ico_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/ico_twtter.svg";
import { ReactComponent as GithubIcon } from "../../assets/ico_github.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/ico_linkedin.svg";
import body2 from "../../pages/auth/text-2.png";

import {
  useSigninUserMutation,
  useSocialAuthMutation,
} from "../redux/api/authApi";
import { useAppDispatch } from "../redux/store";
import { setModule } from "../redux/features/genieSlice";
import { useSubscribeNewsMutation } from "../redux/api/authApi";


const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>

const signinSchema = object({
  name: string().min(1, "Full name is required"),
  PhoneNumber: string().min(1, "Phone Number is required"),
  help: string().min(1, "help is required"),
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters"),
});

export type SigninInput = TypeOf<typeof signinSchema>;

const contactusform = object({
  name: string().min(1, "Full name is required"),
  email: string()
  .min(1, "Email address is required")
  .email("Email address is invalid"),
  phone: string().min(1, "Phone Number is required"),
  subject: string(),
  message: string().min(1, "help is required"),
})


export type ContactUsForm = TypeOf<typeof contactusform>

const ContactusPage = () => {

  const [signinUser, signinState] = useSigninUserMutation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [authSocial, socialState] = useSocialAuthMutation();

  const REDIRECT_URI = window.location.href;

  const [contactVal, setContactVal] = useState<ContactUsForm>({name: '', email: '', phone: '', subject: '', message: ''})
  const [contactusMutation, contactusState] = useContactusMutation();

  useEffect(() => {
    
    if (signinState.isSuccess) {
      toast.success("Login Success");

      localStorage.setItem("logged_in", "true");

      if (!signinState.data.verified) navigate("/verify");
      else {
        if (signinState.data.role === "user") {
          localStorage.setItem("module", "SMART CONTRACTS");
          dispatch(setModule("SMART CONTRACTS"));
          navigate("/codegenie/smart_contracts");
        } else {
          navigate("/admin/dashboard");
        }
      }
    }
    if (signinState.isError) {
      localStorage.setItem("logged_in", "false");
      
      if (Array.isArray((signinState.error as any).data.detail)) {
        (signinState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((signinState.error as any).data.detail);
    }
  }, [signinState]);

  useEffect(() => {
    if (socialState.isSuccess) {
      toast.success("Social signup success");
      
      localStorage.setItem("logged_in", "true");

      if (socialState.data.role === "user") {
        localStorage.setItem("module", "SMART CONTRACTS");
        dispatch(setModule("SMART CONTRACTS"));
        navigate("/codegenie/smart_contracts");
      } else {
        navigate("/admin/dashboard");
      }
    }
    if (socialState.isError) {
      
      localStorage.setItem("logged_in", "false");

      if (Array.isArray((socialState.error as any).data.detail)) {
        (socialState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((socialState.error as any).data.detail);
    }
  }, [socialState]);

  const methods = useForm<ContactUsForm>({
    resolver: zodResolver(contactusform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<ContactUsForm> = (values) => {
    contactusMutation(values);
  };


  const handleContactSubmit = () =>{
    contactusMutation(contactVal)
    .then((response) => {
      if ('data' in response) {
      const status = response.data.message;
      console.log(status);
      toast.success(status);
      setContactVal({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      });
    } else {
      // Error case
      console.error('Error sending query:', response.error);
    }
    })
    .catch((error) => {
      console.error('Error sending query:', error);
     
    });
  }
  const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();
  
  const methodsfoot = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });
  const {
    register : RegisterFoot,
    handleSubmit: handleSubmitFoot,
    formState: { errors:Errors },
  } = methodsfoot;
  const onSubmitHandlerFoot: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };
  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .unwrap()
      .then((response) => {
        console.log('news subscription=>', response);
          const status = response.status;
        
          console.log(status);
          if (status === 'success') {
            toast.success(response.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
      })
      .catch((error) => {
        toast.error(error?.data?.detail ?? 'Something went wrong!');
        console.error('Error sending query:', error);
      });
  }
  return (
    <>
     <div className="container"></div>
     <div className="row">
      <div className="col-md-6 text-white text-center mt-2">
        <div className="px-sm-0 px-2">
          <div>
            <div className="Ac-1">        
            <div className="">
              <Grid  item xs={6} position="relative" >
              <img className="SignupBackground" src={contactus} alt="Signup Background"/>
            </Grid>
            </div>
          </div> <br></br> <br></br>     
          </div>     
        </div>    
      </div>
      
      <div className="col-md-6 my-auto">      
        <div className="px-sm-0 px-2">
        <Box
                position="absolute"
                top={50}
                display="flex"
                alignItems="center"
                gap={4}
              >
              <div className="">
                <Typography marginTop={-3} variant="h5" fontWeight={600} color="text.secondary" onClick={() => navigate('/')}>
                <img width={44} height={44} src={Logo} alt="Logo" onClick={() => navigate('/')} />
                  CodeGenie
                </Typography>
                </div>
              </Box>
        <p className="Sign-1">
              <Box width={464}>
                <div className="contact-2">
                <Typography variant="h4" mb={4} color="text.secondary">
                Drop us a query
                </Typography>
                </div>
                <FormProvider {...methods}>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit(onSubmitHandler)}
                  >
                    <Stack gap={3}>
                      <FormControl fullWidth>
                        <Typography mb={1} color="text.secondary">
                        Name
                        </Typography>
                        <TextField
                        {...register("name")}
                        required
                        placeholder="Enter full name"
                        error={!!errors["name"]}
                        helperText={errors["name"]?.message}
                        onChange={(e)=>setContactVal({...contactVal, name: e.target.value})}
                      />
                      </FormControl>
                      <FormControl fullWidth>
                        <Typography mb={1} color="text.secondary">
                        Email address
                        </Typography>
                        <TextField
                          {...register("email")}
                          type="email"
                          placeholder="Enter your mail"
                          error={!!errors["email"]}
                          helperText={errors["email"]?.message} 
                          onChange={(e)=>setContactVal({...contactVal, email: e.target.value})}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Typography mb={1} color="text.secondary">
                        Phone number
                        </Typography>
                        <TextField
                        required
                          {...register("phone")}
                          type="Phone Number"
                          placeholder="Phone number"
                          error={!!errors["phone"]}
                          helperText={errors["phone"]?.message} 
                          onChange={(e)=>setContactVal({...contactVal, phone: e.target.value})}
                        />
                      </FormControl>
                      
                      <FormControl fullWidth > 
                   
                        <Typography mb={1} color="text.secondary">
                        How we can help
                        </Typography>
                        
                        <TextField
                          {...register("message")}
                          type="Help"
                          placeholder="let us know about your query..."
                          error={!!errors["message"]}
                          helperText={errors["message"]?.message} 
                          onChange={(e)=>setContactVal({...contactVal, message: e.target.value})}
                          
                        />
                      
                      </FormControl>
                    </Stack>
                    <LoadingButton
                      // loading={signinState.isLoading}
                      type="submit"
                      fullWidth
                      sx={{
                        height: 56,
                        background:
                          "linear-gradient(90deg, #036AB7 0%, #4BA5EB 100%)",
                        color: "white",
                        mt: 4,
                      }}
                      onClick={handleContactSubmit}
                    >
                    Get Started
                    </LoadingButton>
                  </Box>
                </FormProvider>
               
              </Box>
            </p> 
        </div>
      </div>
    </div> 
    <footer>
      <div className="py-5 mx-sm-0 mx-2 backgroundbg-12">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 text-white text-lg-start text-center">
            <div>
              <div>
                {/* <img src={logo} alt="" /> */}
                <img src={img54} className="img-fluid" alt="" />

              </div>
              <p className="fw_samibold">
                OceSha/Code Genie Los Angeles, Ca 310-748-8901
              </p>
            </div>
          </div>
          <div className="col-lg-3 text-white d-lg-none d-block  text-lg-start text-center">
            <div>
              <h6 className="f_heading mb-4">
                Get news in your mailbox right away!
              </h6>
              <p className="fw_samibold">
                In addition, stay in loop for our exclusive launches and limited
                time deals.
              </p>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Search" />
              <button className="btn btn-primary" type="submit">
                Subscribe
              </button>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Products</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a href="https://www.techwarz.ai/courses/ArtificialIntelligence">AI Course</a>
                  </li>
                  <li>
                    <a href="https://consulting.code-genie.ai/">AI Consulting</a>
                  </li>
                  <li>
                    <a href="https://www.amazon.com/TECH-WARZ-Rise-Artificial-Intelligence-ebook/dp/B0C7BPRX9J/ref=sr_1_1?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-1">AI Book</a>
                  </li>
                  <li>
                    <a href="https://www.amazon.com/TECH-WARZ-BLOCKCHAIN-CRYPTOCURRENCY-Money-ebook/dp/B0C83TGQSY/ref=sr_1_2?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-2">Blockchain Book</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Information</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a href="/aboutuspage">About Us</a>
                  </li>
                  <li>
                    <a href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873">Affiliate</a>
                  </li>
                  <li>
                    <a href="/contactuspage">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4  text-lg-start text-sm-end">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Support</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a href="/terms">Terms and Conditions</a>
                  </li>
                  <li>
                    <a href="privacy">Privacy &amp; Cookie policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-white d-lg-block d-none">
            <div>
              <h5 className="mb-4 f_heading">
                Get news in your mailbox right away!
              </h5>
              <p className="fw_samibold">
                In addition, stay in loop for our exclusive launches and limited
                time deals.
              </p>
            </div>
            {/* News Subscribe */}
            <FormProvider {...methodsfoot}>
         <div className="input-group mb-3" onSubmit={handleSubmitFoot(onSubmitHandlerFoot)}>
                    <input className={`form-control ${Errors["email"] ? "error" : ""}`}
                      {...RegisterFoot("email")}
                      type="email"
                      placeholder="Enter your mail"
                      // error={!!errors["email"]}
                      // helperText={errors["email"]?.message}
                      onChange={(e) => setSubscribeVal({ ...subscribeVal, email: e.target.value })} />
                    <button className="btn btn-primary" type="submit" onClick={handleSubscribeNews}>
                      Subscribe
                    </button>
                    {Errors["email"] && (
                      <span className="error-message">{Errors["email"]?.message}</span>
                    )}
                  </div>
                  </FormProvider>
        
                  {/*  */}
          </div>
        </div>
      </div>
    </div>
    </footer>

    </>
  );
};

export default ContactusPage;