import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Container,
  Stack,
  SvgIcon,
  Divider,
  FormControl,
  TextField,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialTwitter,
  LoginSocialGithub,
  LoginSocialLinkedin,
  IResolveParams,
} from "reactjs-social-login";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { ReactComponent as GoogleIcon } from "../../assets/ico_google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/ico_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/ico_twtter.svg";
import { ReactComponent as GithubIcon } from "../../assets/ico_github.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/ico_linkedin.svg";

import BackSignup from "../../assets/back_signup.png";
import Logo from "../../assets/logo_white.png";

import {
  useSignupUserMutation,
  useSocialAuthMutation,
} from "../../redux/api/authApi";
import { useAppDispatch } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";
import { useSubscribeNewsMutation } from "../../redux/api/authApi";
import { useUnSubscribepaymentMutation } from "../../redux/api/authApi";
import img54 from '../../assets/newimg/logo.png';


const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>

const signupSchema = object({
  name: string().min(1, "Full name is required"),
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters"),
  passwordConfirm: string().min(1, "Please confirm your password"),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "Passwords do not match",
});

export type SignupInput = TypeOf<typeof signupSchema>;

const SignupPage = () => {
  const [termsCheck, setTermsCheck] = useState(false);

  const [authSocial, socialState] = useSocialAuthMutation();
  const [signupUser, signupState] = useSignupUserMutation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<SignupInput>({
    resolver: zodResolver(signupSchema),
  });

  const REDIRECT_URI = window.location.href;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (socialState.isSuccess) {
      toast.success("Social signup success");
      localStorage.setItem("logged_in", "true");
      if (socialState.data.role === "user") {
        localStorage.setItem("module", "SMART CONTRACTS");
        dispatch(setModule("SMART CONTRACTS"));
        navigate("/codegenie/smart_contracts");
      } else {
        navigate("/admin/dashboard");
      }
    }
    if (socialState.isError) {
      if (Array.isArray((socialState.error as any).data.detail)) {
        (socialState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((socialState.error as any).data.detail);
    }
  }, [socialState]);

  useEffect(() => {
    if (signupState.isSuccess) {
      toast.success("User created successfully");
      navigate("/signin");
    }
    if (signupState.isError) {
      if (Array.isArray((signupState.error as any).data.detail)) {
        (signupState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((signupState.error as any).data.detail);
    }

  }, [signupState]);

  const onSubmitHandler: SubmitHandler<SignupInput> = (values) => {
    signupUser(values);
    navigate('/verify');
  };



  const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();
  

  const methodsfoot = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register : RegisterFoot,
    handleSubmit: handleSubmitFoot,
    formState: { errors:Errors },
  } = methodsfoot;

  const onSubmitHandlerFoot: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .unwrap()
      .then((response) => {
        console.log('news subscription=>', response);
          const status = response.status;
        
          console.log(status);
          if (status === 'success') {
            toast.success(response.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
      })
      .catch((error) => {
        toast.error(error?.data?.detail ?? 'Something went wrong!');
        console.error('Error sending query:', error);

      });
  }
  return (
    <>
       <div className="container"></div>
     <div className="row my-sm-5 mb-5">
      <div className="col-md-5 text-white text-center mt-2">
        <div className="px-sm-0 px-2">
          <div>
            <div className="Ac-1">  
            <div>
              <Grid item xs={6} position="relative">
            <img className="signupbg" src={BackSignup} alt="Signup Background" />
            <Box
              position="absolute"
              left={32}
              top={50}
              display="flex"
              alignItems="center"
              gap={1}
              width={450}
            >
              <img src={Logo} alt="Logo" onClick={() => navigate('/')} />
              <Typography variant="h5" fontWeight={600} color="white" onClick={() => navigate('/')}>
                CodeGenie
              </Typography>
            </Box>
            <Typography
              position="absolute"
              bottom={103}
              left={32}
              variant="h4"
              fontWeight={600}
              color="white"
            >
              Audit, Fix, Refactor, Document any Code Within Seconds
            </Typography>
          </Grid>
            </div>
          </div> <br></br> <br></br>          
          </div>
        </div>  
      </div>
      <div className="col-md-7 my-auto">
        <div className="px-sm-0 px-2">
          <div className="sign-up" >
        <Grid 
            item
            xs={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
           
          >
            <Box className="">
              <Typography color="text.secondary" variant="h4" fontWeight={600}>
                Create an account
              </Typography>
              <Typography color="text.secondary" mt={4}>
                Sign up with social
              </Typography>
              <Stack direction="column" alignItems="center">
                <Box
                  width={56}
                  height={56}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border="1px solid #CACBCC"
                  borderRadius="50%"
                >
                  <LoginSocialGoogle
                    client_id={process.env.REACT_APP_GG_APP_ID || ""}
                    scope="email"
                    onResolve={({ provider, data }: IResolveParams) => {
                      if (data)
                        authSocial({
                          provider: provider as string,
                          email: data?.email||"" as string,
                          name: data?.name||"" as string,
                          id: data.sub as string
                        });
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <SvgIcon>
                      <GoogleIcon />
                    </SvgIcon>
                  </LoginSocialGoogle>
                </Box>
                
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="center"
                gap={1}
                height={44}
                mt={3}
              >
                <Divider sx={{ flexGrow: 1 }} />
                <Typography>Or</Typography>
                <Divider sx={{ flexGrow: 1 }} />
              </Stack>
              <FormProvider {...methods}>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit(onSubmitHandler)}
                >
                  <Stack gap={3}>
                    <FormControl fullWidth>
                      <Typography mb={1} color="text.secondary">
                        Name
                      </Typography>
                      <TextField
                        {...register("name")}
                        placeholder="Enter full name"
                        error={!!errors["name"]}
                        helperText={errors["name"]?.message}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography mb={1} color="text.secondary">
                        Email address
                      </Typography>
                      <TextField
                        {...register("email")}
                        type="email"
                        placeholder="Enter your email"
                        error={!!errors["email"]}
                        helperText={errors["email"]?.message}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography mb={1} color="text.secondary">
                        Password
                      </Typography>
                      <TextField
                        {...register("password")}
                        type="password"
                        placeholder="min 8 characters"
                        error={!!errors["password"]}
                        helperText={errors["password"]?.message}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography mb={1} color="text.secondary">
                        Confirm Password
                      </Typography>
                      <TextField
                        {...register("passwordConfirm")}
                        type="password"
                        placeholder="Same as previous"
                        error={!!errors["passwordConfirm"]}
                        helperText={errors["passwordConfirm"]?.message}
                      />
                    </FormControl>
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        value={termsCheck}
                        onChange={(e) => setTermsCheck(e.target.checked)}
                      />{" "}
                      <Typography>
                        I agree to the{" "}
                        <Link
                          to="/terms"
                          target="_blank"
                          style={{
                            color: "#0168B5",
                          }}
                        >
                          Terms 
                        </Link> 
                        {" & "}  
                         <Link
                          to="/privacy"
                          target="_blank"
                          style={{
                            color: "#0168B5",
                          }}
                        >
                           Privacy Policy 
                        </Link>
                      </Typography>
                    </Box>
                  </Stack>
                  <LoadingButton
                    fullWidth
                    sx={{
                      height: 56,
                      background:
                        "linear-gradient(90deg, #036AB7 0%, #4BA5EB 100%)",
                      color: "white",
                      mt: 4,
                    }}
                    loading={signupState.isLoading}
                    type="submit"
                    disabled={!termsCheck}
                  >
                    Sign Up
                  </LoadingButton>
                </Box>
              </FormProvider>
              <Typography mt={3}>
                Have an account?{" "}
                <Link
                  to="/handleSubmit"
                  style={{
                    color: "#0168B5",
                  }}
                >
                  Sign In
                </Link>
              </Typography>
            </Box>
          </Grid>  
          </div>       
        </div>
      </div>
    </div>


    <footer>
      <div className="sinupfoot">
      <div className="py-5 mx-sm-0 mx-2 backgroundbg">
      <div className="container">
        <div className="row px-sm-0 px-2">
          <div className="col-lg-3 text-white text-lg-start text-center">
            <div>
              <div>
                {/* <img src={logo} alt="" /> */}
                <img src={img54} className="img-fluid" alt="" />

              </div>
              <p className="fw_samibold">
                OceSha/Code Genie Los Angeles, Ca 310-748-8901
              </p>
            </div>
          </div>
          <div className="col-lg-3 text-white d-lg-none d-block  text-lg-start text-center">
            <div>
              <h6 className="f_heading mb-4">
                Get news in your mailbox right away!
              </h6>
              <p className="fw_samibold">
                In addition, stay in loop for our exclusive launches and limited
                time deals.
              </p>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Search" />
              <button className="btn btn-primary" type="submit">
                Subscribe
              </button>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Products</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a target="_blank" href="https://ai-course.code-genie.ai/courses/ArtificialIntelligence">AI Course</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://consulting.code-genie.ai/">AI Consulting</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.amazon.com/TECH-WARZ-Rise-Artificial-Intelligence-ebook/dp/B0C7BPRX9J/ref=sr_1_1?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-1">AI Book</a>
                  </li>
                  <li>
                    <a target="_blank"  href="https://www.amazon.com/TECH-WARZ-BLOCKCHAIN-CRYPTOCURRENCY-Money-ebook/dp/B0C83TGQSY/ref=sr_1_2?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-2">Blockchain Book</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Information</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a target="_blank" href="/aboutuspage">About Us</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873">Affiliate</a>
                  </li>
                  <li>
                    <a target="_blank" href="/contactus">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4  text-lg-start text-sm-end">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Support</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a target="_blank" href="/terms">Terms and Conditions</a>
                  </li>
                  <li>
                    <a target="_blank" href="privacy">Privacy &amp; Cookie policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-white d-lg-block d-none">
            <div>
              <h5 className="mb-4 f_heading">
                Get news in your mailbox right away!
              </h5>
              <p className="fw_samibold">
                In addition, stay in loop for our exclusive launches and limited
                time deals.
              </p>
            </div>
            {/* News Subscribe */}
            <FormProvider {...methodsfoot}>
         <div className="input-group mb-3" onSubmit={handleSubmitFoot(onSubmitHandlerFoot)}>
                    <input className={`form-control ${Errors["email"] ? "error" : ""}`}
                      {...RegisterFoot("email")}
                      type="email"
                      placeholder="Enter your mail"
                      // error={!!errors["email"]}
                      // helperText={errors["email"]?.message}
                      onChange={(e) => setSubscribeVal({ ...subscribeVal, email: e.target.value })} />
                    <button className="btn btn-primary" type="submit" onClick={handleSubscribeNews}>
                      Subscribe
                    </button>
                    {Errors["email"] && (
                      <span className="error-message">{Errors["email"]?.message}</span>
                    )}
                  </div>
                  </FormProvider>

{/* <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Enter your mail" />
            <button className="btn btn-primary" type="submit">
              Subscribe
            </button>
          </div> */}
                  {/*  */}
          </div>
        </div>
      </div>
    </div>
    </div>
    </footer>

    </>

  );
};

export default SignupPage;
