import React, { useEffect, useState } from 'react';
import { useGetOrderSuccessQuery } from '../../redux/api/subscriptionApi';
import { Container, Stack, Typography, Button } from '@mui/material';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { setModule } from '../../redux/features/genieSlice';


const SuccessPage = () => {
    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);
    //console.log( urlParams.get('session_id') ); // 159


    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {data, error, isSuccess} = useGetOrderSuccessQuery({"session_id":String(urlParams.get('session_id'))});
     

    useEffect(() => {
        if (isSuccess) {
            localStorage.setItem("payment", "Success");
        }
      }, [isSuccess]);

      const getModule = urlParams.get("module");
      const subscription_level = urlParams.get("subscription_level") || "";
      
    return (
        <>
            <Container>
                <Stack height="100vh" justifyContent="center" alignItems="center">
                    
                    <Typography variant='h2'>Thank You!</Typography>
                    <br></br>
                    <Button
                        sx={{
                        background:
                            "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                        color: "white",
                        borderRadius: "4px",
                        padding: "12px 16px",
                        height: "44px",
                        }}
                        onClick={() => {
                           
   
                            if(getModule == "any_code"){
                                
                                localStorage.setItem("module", "Any Code");
                                localStorage.setItem("free_plan", "0");
                                localStorage.setItem("subscription_level",subscription_level);
                                dispatch(setModule("Any Code"));
                                navigate("/codegenie/any_code");
                            }
                            else if(getModule == "smart_contracts")
                            {
                                localStorage.setItem("module", "SMART CONTRACTS");
                                dispatch(setModule("SMART CONTRACTS"));
                                navigate("/codegenie/smart_contracts");
                                
                            }
                            
                        }}
                    >
                        Dashboard
                    </Button>
                </Stack>
            </Container>
        
    </>)
}

export default SuccessPage;