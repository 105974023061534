import { useEffect, useState } from "react";
import { useStripe, useElements, CardElement, PaymentElement } from "@stripe/react-stripe-js";
import { useLazyCreateSubscriptionQuery } from "../../redux/api/subscriptionApi";
import { Button, Card, Container, Stack } from "@mui/material";
import { useAppSelector } from "../../redux/store";
import { useNavigate } from "react-router-dom";


const CheckoutPage = () => {
    //const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState<any>(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [createSubscription, subscriptionState] = useLazyCreateSubscriptionQuery();

    const user = useAppSelector((state) => state.userState.user);
    const paymentState = useAppSelector((state) => state.paymentState);

    const navigate = useNavigate();

    useEffect(() => {
      if(!user?.email){
        navigate("/");
      }
    }, []);
    
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);

        const cardElement = elements.getElement(CardElement);

        try {
            if (cardElement) {
                const {error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                });
            
    
                if (error) {
                    console.log(error);
                    //setError(error?.message);
                    return;
                }

                const { id } = paymentMethod;
                const response = await createSubscription({
                    "email": user?.email||"",
                    "price_id": String(paymentState.payments?.price_id),//"price_1NhebhC4v20e2synVHZbFWfs",
                    "stripeToken": id,
                });

                if (response.error) {
                    console.log(response.error)
                    //setError(response?.error);
                    return;
                }
                setIsProcessing(false);
                setSuccess(true);
            }
        } catch (error) {
            console.log(error)
            setIsProcessing(false);
            //setError(error?.message);
        }
    };

    return (
    <Container>
        <Stack height="100vh" justifyContent="center" alignItems="center">
            <Card sx={{ padding: 2 ,  width: "50%", height: "50%"}}>
              <form onSubmit={handleSubmit}>
                <CardElement />
                {error && <p style={{ color: "red" }}>{error}</p>}
                {success && <p style={{ color: "green" }}>Subscription created!</p>}
                <Button 
                  size="large"
                  variant="contained"
                  sx={{ mt: 2 }}
                  type="submit"
                  disabled={isProcessing || !stripe || !elements} id="submit">
                <span id="button-text">
                  {isProcessing ? "Processing ... " : "Pay now"}
                </span>
              </Button>
              </form>
            </Card>
        </Stack>
        </Container>
    );
};

export default CheckoutPage;
