import {
  Box,
  Button,
} from "@mui/material";

import {
  useLazyGetProductQuery,
  useGetAddOnQuery,
} from "../redux/api/productApi";
import { FormEvent, useEffect, useState, useContext, useMemo, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Routes, Route, useLocation } from "react-router-dom";
import { IPlanDetail } from "../redux/api/types";

import "./pricing.page.css";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { setModule } from "../redux/features/genieSlice";
import { setPayment } from "../redux/features/paymentSlice";

import { useLazyCreateCheckoutSessionQuery } from "../redux/api/subscriptionApi";
import { getDesignTokens, ColorModeContext } from "../theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";


const PricingPage = () => {

  // Check if the user is logged in
  const isAuthenticated = localStorage.getItem("logged_in");

  const [mode, setMode] = useState<PaletteMode>("dark");
  const userRole = localStorage.getItem('userRole');
  console.log('role of the user3=>' + userRole);


  const colorMode1 = useMemo(
    () => ({

      toggleColorMode: () => {
        {
          userRole === 'admin' ?
            setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "light" : "light"
            ) : setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "dark" : "dark"
            )
        }
      },
    }),
    []
  );



  const theme1 = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const [getProduct, getState] = useLazyGetProductQuery();
  const [createCheckoutSession, checkoutState] = useLazyCreateCheckoutSessionQuery();
  const [period, getPeriodState] = useState("yearly");
  const [productModule, setProductModule] = useState("");
  const [additionalContract, setAdditionalContract] = useState(0);
  const [addon, setAddOn] = useState({
    plan_name: "",
    price: 0,
    price_id: "",
    product_id: "",
  });
  const [plans, setPlans] = useState<IPlanDetail[]>([
    {
      user: 1,
      plan_name: "",
      total_wishes: 0,
      price: "",
      period: "",
      product_id: "",
      price_id: "",
      subscription_level: "",
      feature: [],
      contracts: 0,
      type: "",
      button_text: "",
    },
  ]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const user = useAppSelector((state) => state.userState.user);
  const genieSelector = useAppSelector((state) => state.genieState);
  //const getAddOn = useGetAddOnQuery({});



  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/api/products/get-add-on`
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.data);
        setAddOn(data.data);
      });
  }, [setAddOn]);

  useEffect(() => {

    const value = {
      _id: "",
      product_name: "CodeGenie",
      //product_module: genieSelector.module ? genieSelector.module : ""
      product_module: "Any Code"
    };
    getProduct(value);
  }, [genieSelector, getProduct]);

  useEffect(() => {

    const { data } = getState;
    if (data?.plan_details) {
      console.log(data?.plan_details);
      setPlans([...data.plan_details]);
      setProductModule(data.product_module);
    }
    else
      setPlans([
        {
          user: 1,
          plan_name: "",
          total_wishes: 0,
          price: "",
          period: "",
          product_id: "",
          price_id: "",
          subscription_level: "",
          feature: [],
          contracts: 0,
          type: "",
          button_text: "",
        },
      ]);
  }, [getState]);

  useEffect(() => {
    if (checkoutState.isSuccess) {
      window.location = checkoutState.data?.checkout_url;
      //window.open(checkoutState.data?.checkout_url, '_blank');
    }
  }, [checkoutState, navigate]);

  function handleSubmit(e: FormEvent<HTMLFormElement>, subscription_level: string) {
    e.preventDefault();
    if (subscription_level === "Free") {
      if (user?.email) {
        navigate("/codegenie/smart_contracts");
      } else {
        navigate("/signup-AnyCode");
      }
    } else {
      if (isAuthenticated != "true") {
        navigate('/signup-AnyCode');
      } else {
        createCheckoutSession({ "emailid": user?.email || "", "username": user?.name || "", "subscription_level": subscription_level });
      }

    }

  }

  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    setAdditionalContract(Number(event.target.value));
  }

  function handlePayNow(e: FormEvent<HTMLFormElement>, subscription_level: string) {
    e.preventDefault();
    if (isAuthenticated != "true") {
      navigate('/signin');
    } else {
      createCheckoutSession({ "emailid": user?.email || "", "username": user?.name || "", "subscription_level": subscription_level });
    }
  }

  return (
    <>

      <div className="priceContainer">
        <div className="price_head">
            {/* <div className="head_top">
            <span>
              <a href="#" className="button">Enterprise</a>
            </span>

            <span>
              <a href="#" className="button whiteButton">Contact Sales</a>
            </span>
          </div> */}

          <div className="head_bottom">
            <h1>Pricing Plans</h1>
            {/* <p>Get 2 months free with yearly plan</p> */}
          </div>



        </div>
        <div className="billingBtn">
          <Box component="div" textAlign="center" sx={{ p: 2 }}>

            <Button
              size="large"
              //variant={period==="monthly"?"contained":"text"}
              sx={{
                background: `${period === "monthly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                color: `${period === "monthly" ? "white" : "inherit"}`,
                paddingX: 2,
                paddingY: 1,
              }}
              onClick={() => {
                getPeriodState("monthly");
              }}
            >
              Monthly billing
            </Button>
            <Button
              size="large"
              //variant={period==="yearly"?"contained":"text"}
              sx={{
                background: `${period === "yearly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                color: `${period === "yearly" ? "white" : "inherit"}`,
                paddingX: 2,
                paddingY: 1,
              }}
              onClick={() => {
                getPeriodState("yearly")
              }}

            >
              Yearly billing
            </Button>

          </Box>
        </div>
<button className="button_leniar px-3"
                      onClick={() => window.history.back()} style={{ fontSize: '13px' , marginBottom:"10px" }}>Go Back</button>
        <div className="price_table">
          {
            plans.filter((item) => item.period === period).map((plan, index) => (
              <div className="columns" key={`plan_item_${index}`}>
                {plan.plan_name === "Developers" && <div className="ribbon ribbon-top-right"><span>Popular</span></div>}
                <ul className="price">
                  <li className="header">{plan.plan_name}</li>
                  <li className="planPrice"><b>$ {Number(plan.price).toLocaleString() + `/Month`}</b></li>
                  <li className="planPrice">{(plan.period === "yearly" && plan.price == "24") && "Billed annually (Save $58/year)"}{(plan.period === "yearly" && plan.price == "66") && "Billed annually (Save $158/year)"}{(plan.period === "yearly" && plan.price == "165") && "Billed annually (Save $398/year)"}</li>
                  {/* {plan.user && <li className="tickli">User: <b>{plan.user}</b></li>} */}
                  {/* <li className="tickli">{productModule === "SMART CONTRACTS" ? "Audits" : "Wishes"}: <b>{plan.total_wishes}</b></li> */}
                  {plan.feature.map((features, index) => (
                    <li className={features == '-' ? 'notickli' : 'tickli'}>{features}</li>
                  ))
                  }
                   {/* <li style={{ fontWeight: 'bolder', fontSize: 'large', textAlign: 'center' }}>Limited Time Offer</li>
                  <li style={{ fontWeight: 'bolder', fontSize: 'large', textAlign: 'center' }}>Free AI Introduction eBook</li> */}
                  <li className="sub_button">
                    <form method="POST" onSubmit={(event) => handleSubmit(event, plan.subscription_level)}>
                      <Button
                        className="button"
                        size="large"
                        fullWidth
                        type="submit"
                        sx={{
                          background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                          color: "white",
                          paddingX: 2,
                          paddingY: 1,
                        }}
                      >
                        {plan.plan_name === "Free" && "Sign in"}
                        {(plan.plan_name === "Individual" || plan.plan_name === "Startups") && "Pay Once"}
                        {(plan.plan_name !== "Individual" && plan.plan_name !== "Startups" && plan.plan_name !== "Free") && plan.button_text}

                      </Button>
                    </form>
                    {/*<Button
                      className="button"
                      size="large"
                      fullWidth
                      sx={{
                        background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                        color: "white",
                        paddingX: 2,
                        paddingY: 1,
                      }}
                      onClick={() => {
                        dispatch(setPayment({'price_id': plan.price_id,'product_id': plan.product_id}));
                        navigate("/checkout");
                      }}
                    >
                    Subscribe
                    </Button>*/}

                  </li>
                </ul>
              </div>

            ))
          }
        </div>

        {/*
        <div className="pricing_botton">
            <div className="pricing_botton_left">
                <h2>Want to try our product </h2>
                <a onClick={() => {
                  localStorage.setItem("module", "SMART CONTRACTS");
                  dispatch(setModule("SMART CONTRACTS"));
                  navigate("/codegenie/smart_contracts");
                }} 
                className="button whiteButton">Try for free</a>
            </div>

            <div className="pricing_botton_right">
                <div className="head">
                    <h2>Add-ons</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the
                        industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                        type
                        and scrambled it to make a type specimen book.
                    </p>
                </div>

                <div className="addonCards">
                    <div className="addoncard">
                        <h2>Extra wishes credits</h2>
                        <p>$5 per 15 wish credit/month</p>
                        <div>I want <input type="number" className="addon_count_input"/> extra wishes credits
                        </div>
                        <h3>Your Total:$5/month</h3>
                        <a href="#" className="button">Get Add-on</a>
                    </div>

                    <div className="addoncard">
                        <h2>Extra wishes credits</h2>
                        <p>$5 per 15 wish credit/month</p>
                        <div>I want <input type="number" className="addon_count_input"/> extra wishes credits
                        </div>
                        <h3>Your Total:$5/month</h3>
                        <a href="#" className="button">Get Add-on</a>
                    </div>

                </div>


            </div>

        </div>*/}

        {/* {(localStorage.getItem("logged_in") == "true") && (
          <div className="pricing_botton">
            <div className="pricing_botton_right">
              <div className="head">
                <h2>1-OFF AUDITS WITH FIX CODE</h2>
                <p>Start up plan can audit additional contracts as one off payments below.
                </p>
              </div>


              <div className="addonCards">
                <div className="addoncard">
                  <h2>Add More Contracts</h2>
                  <p>${(addon.price).toLocaleString()} per contract</p>
                  <div>I want <input type="number" className="addon_count_input" onChange={(event) => handleOnChange(event)} /> extra contracts
                  </div>
                  <h3>Your Total:${(additionalContract * addon.price).toLocaleString()} 1-time payment</h3>
                  <form method="POST" onSubmit={(event) => handlePayNow(event, "add-on")}>
                    <Button
                      className="button"
                      size="large"
                      fullWidth
                      type="submit"
                      sx={{
                        background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                        color: "4BA5EB",
                        paddingX: 2,
                        paddingY: 1,
                      }}
                    >
                      Pay now
                    </Button>
                  </form>
                </div>
              </div>

            </div>

          </div>
        )} */}
      </div>

      <p></p>
      <p></p>
    </>
  );
};

export default PricingPage;